import * as React from 'react';
import styled, { css } from 'styled-components';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
    margin: ${props.margin} !important;
  `}
`;

const NumericBox = (props) => {
  const { valid, validationMessage, width, margin, touched, modified, visited, label, ...rest } = props;
  const numericTextBox = (
    <NumericTextBox
      {...rest}
      valid={valid}
      touched={touched?.toString()}
      modified={modified?.toString()}
      visited={visited?.toString()}
    />
  );

  return (
    <Container $width={width} className={'k-form-fieldset'} margin={margin}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={props.value ?? null}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {numericTextBox}
        </FloatingLabel>
        : numericTextBox
      }
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default NumericBox;