import DesignationListPageActionTypes from './designation-list-page-action-types';
import { sortingToStringWithSubstitutes } from '../../../utils/objectUtils';
import axios from 'axios';
import { USER_TYPES } from '../../../resources/userTypes';
import { DESIGNATION_STATUS } from '../../../resources/designationStatus';

export const loadDesignationListPage = (payload) => async(dispatch, getState) => {
  const state = getState().designationListPage;
  dispatch(findDesignations());
  if (!state.filterStudyPrograms) {
    dispatch(searchDesignationsFilterStudyPrograms());
  }
  if (!state.filterResidents) {
    dispatch(searchDesignationsFilterResidents());
  }
  if (!state.filterDepartments) {
    dispatch(searchDesignationsFilterDepartments());
  }
  dispatch(loadDesignationStatuses({ localizationService: payload.localizationService }));
};

export const findDesignations = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_START });
  const state = getState().designationListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToStringWithSubstitutes(state.sorting, [
      { originalName: 'resident', substituteName: 'Name' }
    ]),
    keyword: state.filter?.keyword,
    studyProgramId: state.filter?.studyProgram?.id,
    studentUserId: state.filter?.resident?.id,
    departmentId: state.filter?.department?.id,
    status: state.filter?.status?.id
  };
  try {
    state.designationsCancelToken?.cancel();
    state.designationsCancelToken = axios.CancelToken.source();
    const designations = await api.get(`api/users/current/designations`, query, state.designationsCancelToken.token);
    dispatch({
      type: DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_END,
      payload: {
        designations: designations
          ? designations.data?.map(designation => ({
            ...designation,
            isSelected: false
          }))
          : [],
        total: designations ? designations.total : 0
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_END });
      throw error;
    }
  }
};

export const filterDesignations = (payload) => (dispatch) => {
  dispatch({
    type: DesignationListPageActionTypes.FILTER_DESIGNATION_LIST,
    payload: payload
  });
  dispatch(findDesignations());
};

export const clearDesignationsFilter = () => (dispatch) => {
  dispatch({
    type: DesignationListPageActionTypes.FILTER_DESIGNATION_LIST,
    payload: {}
  });
  dispatch(findDesignations());
};

export const sortDesignations = (payload) => (dispatch) => {
  dispatch({
    type: DesignationListPageActionTypes.SORT_DESIGNATION_LIST,
    payload: payload
  });
  dispatch(findDesignations());
};

export const searchDesignationsFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().designationListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};

export const searchDesignationsFilterResidents = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_START });
  const state = getState().designationListPage;
  try {
    state.filterResidentsCancelToken?.cancel();
    state.filterResidentsCancelToken = axios.CancelToken.source();
    const users = await api.get(`api/users_search`, {
      keyword: payload?.keyword,
      type: USER_TYPES.STUDENT
    }, state.filterResidentsCancelToken.token);
    dispatch({
      type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_END,
      payload: {
        residents: users?.data?.map(element => (
          {
            id: element.id,
            fullName: `${element.name} ${element.surname}`
          }
        ))
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_END });
      throw error;
    }
  }
};

export const searchDesignationsFilterDepartments = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().designationListPage;
  try {
    state.filterDepartmentsCancelToken?.cancel();
    state.filterDepartmentsCancelToken = axios.CancelToken.source();
    const departments = await api.get('api/departments', query, state.filterDepartmentsCancelToken.token);
    dispatch({
      type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_END,
      payload: {
        departments: departments ? departments.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_END });
      throw error;
    }
  }
};

export const loadDesignationStatuses = (payload) => async(dispatch) => {
  const localizationService = payload?.localizationService;
  const statuses = Object.keys(DESIGNATION_STATUS)
    .map(key => ({
      id: DESIGNATION_STATUS[key],
      text: localizationService?.toLanguageString(`designationStatus.${DESIGNATION_STATUS[key]}`)
    }));
  dispatch({
    type: DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_DESIGNATION_STATUSES,
    payload: {
      designationStatuses: statuses
    }
  });
};

export const selectDesignation = (payload) => (dispatch, getState) => {
  const state = getState().designationListPage;
  let selectedDesignations = [];
  let selectedAll = true;
  if (!state.designations) {
    selectedAll = false;
  }
  const designations = state.designations.map(designation => {
    let isSelected = designation.isSelected;
    if (designation.id === payload.id) {
      isSelected = !isSelected;
    }
    if (isSelected) {
      selectedDesignations.push({
        id: designation.id,
        status: designation.status
      });
    } else {
      selectedAll = false;
    }
    return {
      ...designation,
      isSelected
    };
  });
  dispatch({
    type: DesignationListPageActionTypes.SELECT_DESIGNATION_LIST,
    payload: {
      designations,
      selectedDesignations,
      selectedAll
    }
  });
};

export const selectAllDesignations = () => (dispatch, getState) => {
  const state = getState().designationListPage;
  let selectedDesignations = [];
  const selectedAll = !state.selectedAll;
  const designations = state.designations?.map(designation => {
    if (selectedAll) {
      selectedDesignations.push({
        id: designation.id,
        status: designation.status
      });
    }
    return {
      ...designation,
      isSelected: selectedAll
    };
  });
  dispatch({
    type: DesignationListPageActionTypes.SELECT_ALL_DESIGNATION_LIST,
    payload: {
      designations,
      selectedDesignations,
      selectedAll
    }
  });
};

export const toggleConfirmDialog = (payload) => async(dispatch, getState) => {
  const { localizationService, confirmFunction, statusForValidation } = payload;
  const state = getState().designationListPage;
  const validSelectedCount = state.selectedDesignations.reduce((counter, designation) => designation.status === statusForValidation ? ++counter : counter, 0);
  const selectedCount = state.selectedDesignations.length;
  let actionText;
  let confirmText;

  if (localizationService && statusForValidation) {
    if (statusForValidation === DESIGNATION_STATUS.SUBMITTED) {
      actionText = localizationService.toLanguageString('designations.approveAction');
    } else if (statusForValidation === DESIGNATION_STATUS.APPROVED) {
      actionText = localizationService.toLanguageString('designations.signAction');
    }

    if (validSelectedCount === 0) {
      confirmText = localizationService.toLanguageString('designations.confirmDialogMessageTemplateForNone').replace('{0}', actionText);
    } else if (selectedCount === validSelectedCount) {
      confirmText = localizationService.toLanguageString('designations.confirmDialogMessageTemplateForAll').replace('{0}', actionText);
    } else if (selectedCount > validSelectedCount) {
      confirmText = localizationService.toLanguageString('designations.confirmDialogMessageTemplateForSome').replace('{0}', validSelectedCount).replace('{1}', selectedCount).replace('{2}', actionText);
    }
  }

  dispatch({
    type: DesignationListPageActionTypes.TOGGLE_DESIGNATION_LIST_CONFIRM_DIALOG,
    payload: {
      confirmFunction,
      confirmText,
      isConfirmButtonDisabled: validSelectedCount === 0
    }
  });
};

export const approveSelectedDesignations = () => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationListPageActionTypes.APPROVE_SELECTED_DESIGNATION_START });
  const state = getState().designationListPage;
  try {
    state.designationCancelToken?.cancel();
    state.designationCancelToken = axios.CancelToken.source();
    let selectedIds = [];
    state.selectedDesignations.map(designation => {
      if (designation.status === DESIGNATION_STATUS.SUBMITTED) {
        selectedIds.push(designation.id);
      }
    });
    await api.post(`api/designations/approve`, selectedIds);
    const designations = state.designations.map(designation => {
      if (selectedIds.includes(designation.id)) {
        return {
          ...designation,
          status: DESIGNATION_STATUS.APPROVED,
          isSelected: false
        };
      }
      return designation;
    });
    dispatch({ type: DesignationListPageActionTypes.TOGGLE_DESIGNATION_LIST_CONFIRM_DIALOG });
    dispatch({
      type: DesignationListPageActionTypes.APPROVE_SELECTED_DESIGNATION_END,
      payload: { designations: designations }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationListPageActionTypes.APPROVE_SELECTED_DESIGNATION_END });
      throw error;
    }
  }
};

export const signSelectedDesignations = () => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationListPageActionTypes.SIGN_SELECTED_DESIGNATION_START });
  const state = getState().designationListPage;
  try {
    state.designationCancelToken?.cancel();
    state.designationCancelToken = axios.CancelToken.source();
    let selectedIds = [];
    state.selectedDesignations.map(designation => {
      if (designation.status === DESIGNATION_STATUS.APPROVED) {
        selectedIds.push(designation.id);
      }
    });
    const signingRedirectUrl = await api.post(`api/designations/sign`, selectedIds, state.designationCancelToken);
    dispatch({ type: DesignationListPageActionTypes.TOGGLE_DESIGNATION_LIST_CONFIRM_DIALOG });
    dispatch({
      type: DesignationListPageActionTypes.SIGN_SELECTED_DESIGNATION_END,
      payload: { signingRedirectUrl }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationListPageActionTypes.SIGN_SELECTED_DESIGNATION_END });
      throw error;
    }
  }
};