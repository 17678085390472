import * as React from 'react';
import { DatePicker as KendoDatePicker, Calendar } from '@progress/kendo-react-dateinputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

const YearPicker = (props) => {
  const { value, valid, validationMessage, children, label, ...rest } = props;
  const kendoDatePicker = (
    <KendoDatePicker
      {...rest}
      value={value && new Date(value, 0)}
      format='yyyy'
      calendar={(props) => { return (<Calendar topView='decade' bottomView='decade' value={props.value} onChange={props.onChange}/>); }}
      onChange={e => props.onChange({
        ...e,
        value: e.value?.getFullYear()
      })}
      valid={valid}
    />
  );

  return (
    <fieldset className={'k-form-fieldset'}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={true}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {kendoDatePicker}
        </FloatingLabel>
        : kendoDatePicker
      }
      {!valid && <Error>{validationMessage}</Error>}
      {children}
    </fieldset>
  );
};

export default YearPicker;