import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, Text } from '../../../ui';
import { ValueItem } from '../../shared/components';
import { loadStudyPlanOverviewPage, approveStudyPlan } from '../actions';
import { StudyPlanCycleList } from '.';
import { withRouter } from '../../../components/withRouter';

const ApproveText = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.approved ? '#94C533' : '#F1726E'
}))``;

class StudyPlanOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleApproveClick = this.handleApproveClick.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanId) {
      this.props.loadStudyPlanOverviewPage(this.props.params.studyPlanId);
    }
  }

  render() {
    const { studyPlan, loading, studyPlanCycles } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${studyPlan?.studyProgramName} ${studyPlan?.year}${localizationService.toLanguageString('StudyPlan.shorthandYear')}/${studyPlan?.year + 1}${localizationService.toLanguageString('StudyPlan.shorthandYear')}`
        }
        headerActions={
          <>
            <Button
              id='edit-button'
              type='button'
              themeColor='primary'
              onClick={this.handleEditClick}
              disabled={studyPlan?.isApproved || studyPlanCycles?.length > 0}
            >
              {localizationService.toLanguageString('buttons.edit')}
            </Button>
            <Button
              id='approve-button'
              type='button'
              disabled={studyPlan?.isApproved}
              onClick={this.handleApproveClick}
            >
              {localizationService.toLanguageString('custom.approve')}
            </Button>
            <Button
              id='copy-button'
              type='button'
              primary='false'
              onClick={this.handleCopyClick}
            >
              {localizationService.toLanguageString('custom.copy')}
            </Button>
          </>
        }
        breadcrumbItems={[
          {
            id: 'study-plans',
            text: localizationService.toLanguageString('StudyPlan.studyPlans')
          },
          {
            id: 'study-plan',
            text: localizationService.toLanguageString('StudyPlan.studyPlan'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlan.confirmed')}
              labelWidth='160px'
              loading={loading}
            >
              <ApproveText approved={studyPlan?.isApproved}>
                {
                  studyPlan?.isApproved
                    ? localizationService.toLanguageString('common.yes')
                    : localizationService.toLanguageString('common.no')
                }
              </ApproveText>
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlan.credits')}
              labelWidth='160px'
              loading={loading}
            >
              {studyPlan?.credits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlan.optionalCredits')}
              labelWidth='160px'
              loading={loading}
            >
              {studyPlan?.optionalCredits}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={0}>
            <TabStripTab title={localizationService.toLanguageString('StudyPlan.cycles')} />
          </TabStrip>
        }
      >
        <StudyPlanCycleList studyPlanId={this.props.params.studyPlanId} studyProgramId={this.props.studyPlan?.studyProgramId}/>
      </OverviewPage>
    );
  }

  handleEditClick() {
    this.props.navigate(`/study_plans/${this.props.params.studyPlanId}`);
  }

  handleCopyClick() {
    this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/copy`);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleApproveClick() {
    this.props.approveStudyPlan(this.props.studyPlan);
  }
}

registerForLocalization(StudyPlanOverviewPage);

const mapStateToProps = state => ({
  studyPlan: state.studyPlanOverviewPage.studyPlan,
  loading: state.studyPlanOverviewPage.loading,
  studyPlanCycles: state.studyPlanCycleList.studyPlanCycles
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanOverviewPage: (payload) => dispatch(loadStudyPlanOverviewPage(payload)),
  approveStudyPlan: (payload) => dispatch(approveStudyPlan(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanOverviewPage));
