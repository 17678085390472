import StudentCycleOverviewActionTypes from './student-cycle-overview-action-types';
import axios from 'axios';
import ValidationError from '../../../../utils/validation-error';
import { CYCLE_STUDENT_STATUS } from '../../../../resources/studyPlanCycleDepartmentStudentStatus';
import { STUDENT_CYCLE_OVERVIEW_DIALOG_TYPES as DialogTypes } from '../../../../resources/StudentCycleOverviewDialogType';
import { SURVEY_TYPES } from '../../../../resources/surveyType';
import { DateFormatter } from '../../components';
import { isDateInPeriod } from '../../../../utils/dateUtils';
import { USER_TYPES } from '../../../../resources/userTypes';
import { ROLE_TYPES as RoleTypes } from '../../../../resources/roleType';
import { DESIGNATION_STATUS } from '../../../../resources/designationStatus';

const compareLevelsAndDates = (a, b) => {
  const levelCompare = a.level.localeCompare(b.level);
  if (levelCompare === 0) {
    return a.lastChangedOn.getTime() - b.lastChangedOn.getTime();
  }
  return levelCompare;
};

const compareDates = (a, b) => b.lastChangedOn.getTime() - a.lastChangedOn.getTime();

const getDefaultDepartment = (availableDepartments) => {
  const nonForeignDepartments = availableDepartments.filter(department => !department.isForeign);
  if (nonForeignDepartments?.length === 1) {
    return nonForeignDepartments[0];
  }
  if (nonForeignDepartments?.length > 1) {
    return nonForeignDepartments.find(item => isDateInPeriod(new Date(), item.dateFrom, item.dateTo)) ?? nonForeignDepartments[0];
  }
  return null;
};

const getSkillsCompletion = (skills) => skills?.every(skill =>
  skill.history?.filter(item => item.level === 'A').length >= skill.requiredA &&
  skill.history?.filter(item => item.level === 'B').length >= skill.requiredB &&
  skill.history?.filter(item => item.level === 'C').length >= skill.requiredC) ?? true;

const addLatestHistoryEntry = (entries, items) => {
  if (items?.length > 0) {
    const latestEntries = [];
    items.forEach(item => {
      const latestItem = item.history?.sort(compareDates)?.[0];
      if (latestItem) {
        latestEntries.push(latestItem);
      }
    });
    if (latestEntries.length > 0) {
      entries.push(latestEntries.sort(compareDates)[0]);
    }
  }
};

const getLatestEntry = (state) => {
  const latestEntries = [];
  addLatestHistoryEntry(latestEntries, state.skills);
  addLatestHistoryEntry(latestEntries, state.additionalSkills);
  addLatestHistoryEntry(latestEntries, state.cases);
  addLatestHistoryEntry(latestEntries, state.additionalCases);
  return latestEntries.filter(item => item != null).sort(compareDates)?.[0];
};

const getCasesCompletion = (cases) => cases?.every(cycleCase => cycleCase.history?.length >= cycleCase.required) ?? true;

const isCycleReadOnly = (status) => status !== CYCLE_STUDENT_STATUS.NOT_STARTED && status !== CYCLE_STUDENT_STATUS.STARTED && status !== CYCLE_STUDENT_STATUS.RETURNED;

const getManagerSurveys = (studentSurveys, studentManagers) => {
  const managerSurveys = studentSurveys?.data.filter(item => item.subjectUserId) ?? [];
  const unratedManagers = studentManagers?.filter(manager => !studentSurveys?.data.some(survey => survey.subjectUserId === manager.id))
    .map(manager => ({ subjectsName: manager.name + ' ' + manager.surname, subjectUserId: manager.id })) ?? [];
  return [...managerSurveys, ...unratedManagers];
};

const getManagerApprovals = (studentsApprovalRequests, studentManagers) => {
  const managerApprovals = studentsApprovalRequests?.filter(item => item.managerUserId) ?? [];
  const notRequestedManagers = studentManagers?.filter(manager => !studentsApprovalRequests?.some(studentApprovalRequest => studentApprovalRequest.managerUserId === manager.id))
    .map(manager => ({ manager: manager.name + ' ' + manager.surname, managerUserId: manager.id })) ?? [];
  return [...managerApprovals, ...notRequestedManagers];
};

const getCycleDepartmentSurveys = (studentSurveys, studentDepartments) => {
  const distinctStudentDepartments = [...new Map(studentDepartments?.data.map(element =>[element['departmentId'], element])).values()];
  const studentCycleDepartmentSurveys = studentSurveys?.data.filter(element => element.surveyType === SURVEY_TYPES.CYCLE_EVALUATION_SURVEY) ?? [];
  const unratedCycleDepartments = distinctStudentDepartments?.filter(studentDepartment => !studentCycleDepartmentSurveys?.some(survey => survey.departmentId === studentDepartment.departmentId)) ?? [];
  return [...studentCycleDepartmentSurveys, ...unratedCycleDepartments];
};

export const loadStudentCycleOverview = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studentId, studyPlanCycleStudentId, intlService } = payload;
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_START });
  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    const [
      cycles,
      cycle,
      departments
    ] = await Promise.all([
      api.get(`api/students/${studentId}/cycles`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/departments`, null, state.studentCycleOverviewCancelToken.token)
    ]);
    const competencies = await api.get(`api/cycles/${cycle.cycleId}/competencies`, state.studentCycleOverviewCancelToken.token);
    const availableCycles = cycles?.filter(cycle => cycle.id !== parseInt(studyPlanCycleStudentId) && (cycle.status === CYCLE_STUDENT_STATUS.NOT_STARTED || cycle.status === CYCLE_STUDENT_STATUS.STARTED))
      .map(cycle => ({
        id: cycle.id,
        cycleId: cycle.cycleId,
        name: cycle.cycle
      }));
    const availableDepartments = departments?.data?.map(department => (
      {
        id: department.studyPlanCycleDepartmentId,
        name: department.departmentName,
        nameWithDate: `${department.departmentName} ${DateFormatter(department.dateFrom, intlService)} - ${DateFormatter(department.dateTo, intlService)}`,
        dateFrom: department.dateFrom,
        dateTo: department.dateTo,
        isForeign: department.departmentIsForeign,
        isExternal: department.departmentIsExternal,
        designationId: department.designationId,
        designationStatus: department.designationStatus,
        studyPlanCycleDepartmentStudentId: department.id,
        credits: department.credits
      }));
    dispatch({
      type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_END,
      payload: {
        readOnly: isCycleReadOnly(cycle.status),
        cycle,
        studyPlanId: cycle?.studyPlanId,
        studyPlanCycleStudentId,
        availableCycles,
        availableDepartments,
        competencies
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_END });
      throw error;
    }
  }
};

export const loadStudentCycleSkills = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studentId, studyPlanCycleStudentId } = payload;
  if (!state.studentSkillsLoaded) {
    let cycleId = state.cycle?.cycle;
    dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_START });
    try {
      state.studentCycleOverviewCancelToken?.cancel();
      state.studentCycleOverviewCancelToken = axios.CancelToken.source();
      let cycle;
      if (!cycleId) {
        cycle = await api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}`, null, state.studentCycleOverviewCancelToken.token);
        cycleId = cycle?.cycleId;
      }
      const [studentSkills, additionalStudentSkills, cycleSkills] = await Promise.all([
        api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/skills`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/additional_skills`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/cycles/${cycleId}/skill_abilities`)
      ]);
      const studentHistorySkills = studentSkills?.data
        .map(item => ({
          ...item,
          lastChangedOn: new Date(item.lastChangedOn),
          date: new Date(item.date)
        }))
        .sort(compareLevelsAndDates);
      const skills = cycleSkills?.data.map(skill => {
        const history = studentHistorySkills?.filter(item => item.cycleSkillAbilityId === skill.id);
        return {
          id: skill.id,
          name: skill.studyProgramSkillAbilityName,
          levelA: history?.filter(item => item.level === 'A').length || 0,
          requiredA: skill.minRequiredLevelA,
          levelB: history?.filter(item => item.level === 'B').length || 0,
          requiredB: skill.minRequiredLevelB,
          levelC: history?.filter(item => item.level === 'C').length || 0,
          requiredC: skill.minRequiredLevelC,
          newLevelA: 0,
          newLevelB: 0,
          newLevelC: 0,
          isExpanded: false,
          history
        };
      });
      const additionalSkills = additionalStudentSkills?.data
        .map(skill => {
          const history = studentHistorySkills?.filter(item => item.cycleSkillAbilityId === skill.cycleSkillAbilityId);
          return {
            id: skill.id,
            cycleSkillAbilityId: skill.cycleSkillAbilityId,
            additionalStudyPlanCycleStudentId: skill.additionalStudyPlanCycleStudentId,
            studyPlanCycleStudentId: skill.studyPlanCycleStudentId,
            name: skill.cycleSkillAbilityName,
            cycleName: skill.cycleName,
            levelA: history?.filter(item => item.level === 'A').length || 0,
            requiredA: skill.minRequiredLevelA,
            levelB: history?.filter(item => item.level === 'B').length || 0,
            requiredB: skill.minRequiredLevelB,
            levelC: history?.filter(item => item.level === 'C').length || 0,
            requiredC: skill.minRequiredLevelC,
            newLevelA: 0,
            newLevelB: 0,
            newLevelC: 0,
            isExpanded: false,
            history
          };
        });
      const skillsCompleted = getSkillsCompletion(skills);
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_END,
        payload: {
          studentSkillsLoaded: true,
          cycle,
          skills,
          additionalSkills,
          skillsCompleted
        }
      });
    } catch (error) {
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_END,
        payload: {
          studentSkillsLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const loadStudentCycleCases = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  if (!state.studentCasesLoaded) {
    dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_START });
    try {
      state.studentCycleOverviewCancelToken?.cancel();
      state.studentCycleOverviewCancelToken = axios.CancelToken.source();
      let cycleId = state.cycle?.cycleId;
      let cycle;
      if (!cycleId) {
        cycle = await api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}`, null, state.studentCycleOverviewCancelToken.token);
        cycleId = cycle?.cycleId;
      }
      const [studentCases, additionalStudentCases, cycleCases] = await Promise.all([
        api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/cases`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/additional_cases`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/cycles/${cycleId}/cases`, null, state.studentCycleOverviewCancelToken.token)
      ]);
      const studentHistoryCases = studentCases?.data
        .map(item => ({
          ...item,
          lastChangedOn: new Date(item.lastChangedOn),
          date: new Date(item.date)
        }))
        .sort(compareDates);
      const cases = cycleCases?.data.map(cycleCase => {
        const history = studentHistoryCases?.filter(item => item.cycleCaseId === cycleCase.id);
        const diagnosesArray = cycleCase.diagnoses?.map(diagnosis => {
          return { code: diagnosis.code, name: diagnosis.name };
        }).sort((a, b) => a.code.localeCompare(b.code));
        return {
          id: cycleCase.id,
          name: cycleCase.studyProgramCaseName,
          studentCases: history?.length || 0,
          required: cycleCase.minRequiredNumber,
          newCases: 0,
          isExpanded: false,
          diagnosesArray,
          history
        };
      });
      const additionalCases = additionalStudentCases?.data.map(cycleCase => {
        const history = studentHistoryCases?.filter(item => item.cycleCaseId === cycleCase.cycleCaseId);
        return {
          id: cycleCase.id,
          cycleCaseId: cycleCase.cycleCaseId,
          name: cycleCase.cycleCaseName,
          cycleName: cycleCase.cycleName,
          additionalStudyPlanCycleStudentId: cycleCase.additionalStudyPlanCycleStudentId,
          studyPlanCycleStudentId: cycleCase.studyPlanCycleStudentId,
          studentCases: history?.length || 0,
          required: cycleCase.minRequiredNumber,
          newCases: 0,
          isExpanded: false,
          diagnoses: cycleCase.diagnoses?.sort().join(', '),
          history
        };
      });
      const casesCompleted = getCasesCompletion(cases);
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_END,
        payload: {
          studentCasesLoaded: true,
          cycle,
          cases,
          additionalCases,
          casesCompleted
        }
      });
    } catch (error) {
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_END,
        payload: {
          studentCasesLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const loadStudentCycleTheoreticalParts = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studentId, studyPlanCycleStudentId } = state;
  if (!state.studentTheoreticalPartsLoaded || payload?.reload) {
    dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_START });
    try {
      state.studentCycleOverviewCancelToken?.cancel();
      state.studentCycleOverviewCancelToken = axios.CancelToken.source();
      let cycle = state.cycle;
      if (cycle == null) {
        cycle = await api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}`, null, state.studentCycleOverviewCancelToken.token);
      }
      const theoreticalParts = await api.get(`api/study_plans/${cycle.studyPlanId}/cycles/${cycle.studyPlanCycleId}/students/${studyPlanCycleStudentId}/theoretical_parts`, null, state.studentCycleOverviewCancelToken.token);
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_END,
        payload: {
          studentTheoreticalPartsLoaded: true,
          cycle,
          theoreticalParts: theoreticalParts?.data ?? []
        }
      });
    } catch (error) {
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_END,
        payload: {
          studentTheoreticalPartsLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const loadStudentCycleCompletion = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studentId, studyPlanId, studyPlanCycleId, studyPlanCycleStudentId } = payload;
  if (!state.studentCycleCompletionLoaded) {
    dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_START });
    try {
      state.studentCycleOverviewCancelToken?.cancel();
      state.studentCycleOverviewCancelToken = axios.CancelToken.source();
      const [cycleStatusHistory, studentManagers, studentSurveys, studentsApprovals, studentDepartments] = await Promise.all([
        api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/status_history`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/managers`, { includeAdditionalManagers: false }, state.studentCycleOverviewCancelToken.token),
        api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/surveys`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/approval_requests`, null, state.studentCycleOverviewCancelToken.token),
        api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/departments`, null, state.studentCycleOverviewCancelToken.token)
      ]);
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_END,
        payload: {
          studentCycleCompletionLoaded: true,
          cycleStatusHistory,
          managerApprovals: getManagerApprovals(studentsApprovals, studentManagers),
          managerSurveys: getManagerSurveys(studentSurveys, studentManagers),
          cycleDepartmentSurveys: getCycleDepartmentSurveys(studentSurveys, studentDepartments),
          cycleManagerSurvey: studentSurveys?.data?.find(item => item.surveyType === SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY)
        }
      });
    } catch (error) {
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_END,
        payload: {
          studentCycleCompletionLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const filterAvailableDepartments = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const availableDepartments = state.availableDepartments.filter(department => payload?.keyword ? department.name?.toLowerCase().includes(payload.keyword?.toLowerCase()) : true);
  dispatch({
    type: StudentCycleOverviewActionTypes.FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_DEPARTMENTS,
    payload: {
      availableDepartments
    }
  });
};

export const filterAvailableManagers = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    const params = {
      type: [USER_TYPES.MENTOR, USER_TYPES.MANAGER],
      keyword: payload?.keyword,
      limit: 100
    };
    const users = await api.get('api/users_search', params, state.studentCycleOverviewCancelToken.token);
    const availableManagers = users?.data?.map(item => ({
      id: item.id,
      name: item.stampNumber
        ? `${item.stampNumber} ${item.name} ${item.surname}`
        : `${item.name} ${item.surname}`
    }));
    dispatch({
      type: StudentCycleOverviewActionTypes.FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_MANAGERS,
      payload: {
        availableManagers
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const loadStudentSkills = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_START });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const { applyNewSkills, studentId } = payload;
  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    const [studentSkills, studentSurveys, studentManagers] = await Promise.all([
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/skills`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/surveys`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/managers`, null, state.studentCycleOverviewCancelToken.token)
    ]);
    const studentHistorySkills = studentSkills?.data
      .map(item => ({
        ...item,
        lastChangedOn: new Date(item.lastChangedOn),
        date: new Date(item.date)
      }))
      .sort(compareLevelsAndDates);
    const skills = state.skills?.map(skill => ({
      ...skill,
      levelA: applyNewSkills ? skill.levelA + skill.newLevelA : skill.levelA,
      newLevelA: applyNewSkills ? 0 : skill.newLevelA,
      levelB: applyNewSkills ? skill.levelB + skill.newLevelB : skill.levelB,
      newLevelB: applyNewSkills ? 0 : skill.newLevelB,
      levelC: applyNewSkills ? skill.levelC + skill.newLevelC : skill.levelC,
      newLevelC: applyNewSkills ? 0 : skill.newLevelC,
      history: studentHistorySkills?.filter(item => item.cycleSkillAbilityId === skill.id)
    }));
    const additionalSkills = state.additionalSkills?.map(skill => ({
      ...skill,
      levelA: applyNewSkills ? skill.levelA + skill.newLevelA : skill.levelA,
      newLevelA: applyNewSkills ? 0 : skill.newLevelA,
      levelB: applyNewSkills ? skill.levelB + skill.newLevelB : skill.levelB,
      newLevelB: applyNewSkills ? 0 : skill.newLevelB,
      levelC: applyNewSkills ? skill.levelC + skill.newLevelC : skill.levelC,
      newLevelC: applyNewSkills ? 0 : skill.newLevelC,
      history: studentHistorySkills?.filter(item => item.cycleSkillAbilityId === skill.cycleSkillAbilityId)
    }));
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
      payload: {
        skills,
        additionalSkills,
        skillsCompleted: getSkillsCompletion(skills),
        managerSurveys: getManagerSurveys(studentSurveys, studentManagers)
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const selectTab = (payload) => (dispatch) => {
  dispatch({
    type: StudentCycleOverviewActionTypes.SELECT_STUDENT_CYCLE_OVERVIEW_TAB,
    payload: {
      selectedTab: payload?.selectedTab
    }
  });
};

export const toggleStudentCycleOverviewSummaryExpanded = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.TOGGLE_STUDENT_CYCLE_OVERVIEW_SUMMARY_EXPANDED });
};

export const increaseSkills = (payload) => (dispatch, getState) => {
  const { skillId, level, isAdditional } = payload;
  const state = getState().studentCycleOverview;
  let skills, additionalSkills;
  if (isAdditional) {
    additionalSkills = state.additionalSkills?.map(skill => ({
      ...skill,
      newLevelA: skill.id === skillId && level === 'A' ? skill.newLevelA + 1 : skill.newLevelA,
      newLevelB: skill.id === skillId && level === 'B' ? skill.newLevelB + 1 : skill.newLevelB,
      newLevelC: skill.id === skillId && level === 'C' ? skill.newLevelC + 1 : skill.newLevelC
    }));
  } else {
    skills = state.skills?.map(skill => ({
      ...skill,
      newLevelA: skill.id === skillId && level === 'A' ? skill.newLevelA + 1 : skill.newLevelA,
      newLevelB: skill.id === skillId && level === 'B' ? skill.newLevelB + 1 : skill.newLevelB,
      newLevelC: skill.id === skillId && level === 'C' ? skill.newLevelC + 1 : skill.newLevelC
    }));
  }
  dispatch({
    type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
    payload: {
      skills,
      additionalSkills
    }
  });
};

export const clearSkills = () => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const skills = state.skills?.map(skill => ({
    ...skill,
    newLevelA: 0,
    newLevelB: 0,
    newLevelC: 0
  }));
  const additionalSkills = state.additionalSkills?.map(skill => ({
    ...skill,
    newLevelA: 0,
    newLevelB: 0,
    newLevelC: 0
  }));
  dispatch({
    type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
    payload: {
      skills,
      additionalSkills
    }
  });
};

export const expandSkill = (payload) => (dispatch, getState) => {
  const { skillId, isAdditional } = payload;
  const state = getState().studentCycleOverview;
  let skills, additionalSkills;
  if (isAdditional) {
    additionalSkills = state.additionalSkills?.map(skill => ({
      ...skill,
      isExpanded: skill.id === skillId ? !skill.isExpanded : skill.isExpanded
    }));
  } else {
    skills = state.skills?.map(skill => ({
      ...skill,
      isExpanded: skill.id === skillId ? !skill.isExpanded : skill.isExpanded
    }));
  }
  dispatch({
    type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
    payload: {
      skills,
      additionalSkills
    }
  });
};

export const closeEditDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG });
};

export const closeDeleteDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG });
};

export const openSkillRegisterDialog = (payload) => (dispatch, getState) => {
  const { localizationService } = payload;
  const state = getState().studentCycleOverview;
  const { availableDepartments } = state;
  const latestEntry = getLatestEntry(state);
  let department = getDefaultDepartment(availableDepartments);
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
    payload: {
      editDialogTitle: localizationService.toLanguageString('studentCycle.skillConfirmDialogTitle'),
      editDialogType: DialogTypes.REGISTER_SKILL,
      editDialogConfirmButtonText: localizationService.toLanguageString('buttons.register'),
      editDialogForm: {
        department,
        manager: latestEntry && {
          id: latestEntry.managerUserId,
          name: latestEntry.managerUserFullName
        },
        medicalHistoryNumber: latestEntry?.medicalHistoryNumber,
        date: new Date()
      },
      editDialogValidationResult: null,
      editDialogError: null
    }
  });
};

export const registerSkills = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const { department, manager, showMentorFields, stampNumber, name, surname, medicalHistoryNumber, date, localizationService, studentId, email } = payload;
  const apiModel = [];
  const baseData = {
    date,
    medicalHistoryNumber,
    studyPlanCycleDepartmentId: department.id,
    managerUserId: manager?.id
  };
  const pushSkillItems = (level, itemsCount, cycleSkillAbilityId, studyPlanCycleStudentId, isAdditional, studyPlanCycleStudentSkillAbilityId) => {
    for (let i = 0; i < itemsCount; i++) {
      apiModel.push({
        ...baseData,
        level,
        cycleSkillAbilityId,
        studyPlanCycleStudentId,
        isAdditional,
        studyPlanCycleStudentSkillAbilityId
      });
    }
  };
  state.skills?.filter(skill => skill.newLevelA > 0 || skill.newLevelB > 0 || skill.newLevelC > 0)
    .forEach(skill => {
      pushSkillItems('A', skill.newLevelA, skill.id, studyPlanCycleStudentId);
      pushSkillItems('B', skill.newLevelB, skill.id, studyPlanCycleStudentId);
      pushSkillItems('C', skill.newLevelC, skill.id, studyPlanCycleStudentId);
    });
  state.additionalSkills?.filter(skill => skill.newLevelA > 0 || skill.newLevelB > 0 || skill.newLevelC > 0)
    .map(skill => {
      pushSkillItems('A', skill.newLevelA, skill.cycleSkillAbilityId, skill.studyPlanCycleStudentId, true, skill.id);
      pushSkillItems('B', skill.newLevelB, skill.cycleSkillAbilityId, skill.studyPlanCycleStudentId, true, skill.id);
      pushSkillItems('C', skill.newLevelC, skill.cycleSkillAbilityId, skill.studyPlanCycleStudentId, true, skill.id);
    });
  try {
    if (showMentorFields) {
      const roles = [RoleTypes.MENTOR];
      const newMentorId = await api.post('api/users', { roles, stampNumber, name, surname, email, isExternal: true });
      for (let i = 0; i < apiModel.length; i++) {
        apiModel[i].managerUserId = newMentorId;
      }
    }
    const response = await api.post(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/skills`, apiModel);
    let successCount = 0;
    let errorCount = 0;
    response?.forEach(item => {
      if (item.statusCode === 201) {
        successCount++;
      } else {
        errorCount++;
      }
    });
    if (errorCount > 0) {
      const messageTemplate = localizationService.toLanguageString('studentCycle.skillRegisterErrorMessage');
      const errorMessage = messageTemplate.replace('{0}', errorCount).replace('{1}', errorCount + successCount).replace('{2}', successCount);
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
        payload: {
          editDialogTitle: localizationService.toLanguageString('studentCycle.skillConfirmDialogTitle'),
          editDialogType: DialogTypes.REGISTER_SKILL,
          editDialogConfirmButtonText: localizationService.toLanguageString('buttons.register'),
          editDialogForm: state.editDialogForm,
          editDialogValidationResult: null,
          editDialogError: errorMessage,
          editDialogLoading: false
        }
      });
    } else {
      dispatch(loadStudentSkills({ applyNewSkills: true, studentId }));
      dispatch(closeEditDialog());
    }
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
        payload: {
          editDialogTitle: localizationService.toLanguageString('studentCycle.skillConfirmDialogTitle'),
          editDialogType: DialogTypes.REGISTER_SKILL,
          editDialogConfirmButtonText: localizationService.toLanguageString('buttons.register'),
          editDialogForm: state.editDialogForm,
          editDialogValidationResult: {
            errorMessage: error.message,
            errors: error.errors
          },
          editDialogError: null,
          editDialogLoading: false
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_END });
      throw error;
    }
  }
};

export const openSkillEditDialog = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { skillIndex, historySkillIndex, isAdditional, localizationService } = payload;
  const studentSkill = isAdditional
    ? state.additionalSkills[skillIndex].history[historySkillIndex]
    : state.skills[skillIndex].history[historySkillIndex];
  const department = state.availableDepartments?.find(department => department.id === studentSkill.studyPlanCycleDepartmentId);
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
    payload: {
      editDialogTitle: localizationService.toLanguageString('studentCycle.skillEditDialogTitle'),
      editDialogType: DialogTypes.EDIT_SKILL,
      editDialogConfirmButtonText: localizationService.toLanguageString('buttons.save'),
      editDialogForm: {
        id: studentSkill.id,
        level: studentSkill.level,
        date: studentSkill.date,
        medicalHistoryNumber: studentSkill.medicalHistoryNumber,
        cycleSkillAbilityId: studentSkill.cycleSkillAbilityId,
        studyPlanCycleStudentId: studentSkill.studyPlanCycleStudentId,
        department,
        manager: {
          id: studentSkill.managerUserId,
          name: studentSkill.managerUserFullName
        },
        isAdditional,
        studyPlanCycleStudentSkillAbilityId: studentSkill.studyPlanCycleStudentSkillAbilityId
      },
      editDialogValidationResult: null,
      editDialogError: null,
      editDialogLoading: false
    }
  });
};

export const saveSkill = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const { localizationService, studentId, department, manager, showMentorFields, stampNumber, name, surname, email, ...data } = payload;
  const apiModel = {
    ...data,
    studyPlanCycleDepartmentId: department.id,
    managerUserId: manager?.id
  };
  try {
    if (showMentorFields) {
      const roles = [RoleTypes.MENTOR];
      const newMentorId = await api.post('api/users', { roles, stampNumber, name, surname, email, isExternal: true });
      apiModel.managerUserId = newMentorId;
    }
    if (data.isAdditional) {
      await api.put(`api/students/${studentId}/cycles/${data.studyPlanCycleStudentId}/skills/${data.id}`, apiModel);
    } else {
      await api.put(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/skills/${data.id}`, apiModel);
    }
    dispatch(loadStudentSkills({ applyNewSkills: false, studentId }));
    dispatch(closeEditDialog());
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
        payload: {
          editDialogTitle: localizationService.toLanguageString('studentCycle.skillEditDialogTitle'),
          editDialogType: DialogTypes.EDIT_SKILL,
          editDialogConfirmButtonText: localizationService.toLanguageString('buttons.save'),
          editDialogForm: state.editDialogForm,
          editDialogValidationResult: {
            errorMessage: error.message,
            errors: error.errors
          },
          editDialogError: null,
          editDialogLoading: false
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_END });
      throw error;
    }
  }
};

export const openSkillDeleteDialog = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { skillIndex, historySkillIndex, isAdditional, localizationService } = payload;
  const skill = isAdditional
    ? state.additionalSkills[skillIndex].history[historySkillIndex]
    : state.skills[skillIndex].history[historySkillIndex];
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
    payload: {
      deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.skillDeleteDialogMessage'),
      deleteDialogType: isAdditional ? DialogTypes.DELETE_ADDITIONAL_SKILL : DialogTypes.DELETE_SKILL,
      deleteDialogItemId: skill.id
    }
  });
};

export const deleteSelectedSkill = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_BEGIN });
  const state = getState().studentCycleOverview;
  const { deleteDialogItemId } = state;
  const { isAdditional, localizationService, studentId } = payload;
  try {
    let studyPlanCycleStudentId;
    let skills, additionalSkills;
    if (isAdditional) {
      additionalSkills = state.additionalSkills?.map(skill => {
        const history = skill.history?.filter(item => item.id !== deleteDialogItemId);
        if (studyPlanCycleStudentId == null) {
          studyPlanCycleStudentId = skill.history?.find(item => item.id === deleteDialogItemId)?.studyPlanCycleStudentId;
        }
        return {
          ...skill,
          history,
          levelA: history?.filter(item => item.level === 'A').length || 0,
          levelB: history?.filter(item => item.level === 'B').length || 0,
          levelC: history?.filter(item => item.level === 'C').length || 0
        };
      });
    } else {
      skills = state.skills?.map(skill => {
        const history = skill.history?.filter(item => item.id !== deleteDialogItemId);
        return {
          ...skill,
          history,
          levelA: history?.filter(item => item.level === 'A').length || 0,
          levelB: history?.filter(item => item.level === 'B').length || 0,
          levelC: history?.filter(item => item.level === 'C').length || 0
        };
      });
    }
    await api.remove(`api/students/${studentId}/cycles/${isAdditional ? studyPlanCycleStudentId : state.studyPlanCycleStudentId}/skills/${deleteDialogItemId}`);
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
      payload: {
        skills,
        additionalSkills
      }
    });
    dispatch(closeDeleteDialog());
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
        payload: {
          deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.skillDeleteDialogMessage'),
          deleteDialogType: DialogTypes.DELETE_SKILL,
          deleteDialogItemId: state.deleteDialogItemId,
          deleteDialogErrorMessage: localizationService.toLanguageString('studentCycle.skillDeleteErrorMessage')
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_END });
      throw error;
    }
  }
};

export const loadStudentCases = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_START });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const { applyNewCases, studentId } = payload;

  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    const [studentCases, studentSurveys, studentManagers] = await Promise.all([
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/cases`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/surveys`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/managers`, null, state.studentCycleOverviewCancelToken.token)
    ]);
    const studentHistoryCases = studentCases?.data
      .map(item => ({
        ...item,
        lastChangedOn: new Date(item.lastChangedOn),
        date: new Date(item.date)
      }))
      .sort(compareDates);
    const cases = state.cases?.map(cycleCase => ({
      ...cycleCase,
      studentCases: applyNewCases ? cycleCase.studentCases + cycleCase.newCases : cycleCase.studentCases,
      newCases: applyNewCases ? 0 : cycleCase.newCases,
      history: studentHistoryCases?.filter(item => item.cycleCaseId === cycleCase.id)
    }));
    const additionalCases = state.additionalCases?.map(cycleCase => ({
      ...cycleCase,
      studentCases: applyNewCases ? cycleCase.studentCases + cycleCase.newCases : cycleCase.studentCases,
      newCases: applyNewCases ? 0 : cycleCase.newCases,
      history: studentHistoryCases?.filter(item => item.cycleCaseId === cycleCase.cycleCaseId)
    }));
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
      payload: {
        cases,
        additionalCases,
        casesCompleted: getCasesCompletion(cases),
        managerSurveys: getManagerSurveys(studentSurveys, studentManagers)
      }
    });

  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const increaseCases = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { cycleCaseId, isAdditional } = payload;
  let cases, additionalCases;
  if (isAdditional) {
    additionalCases = state.additionalCases?.map(cycleCase => ({
      ...cycleCase,
      newCases: cycleCase.id === cycleCaseId ? cycleCase.newCases + 1 : cycleCase.newCases
    }));
  } else {
    cases = state.cases?.map(cycleCase => ({
      ...cycleCase,
      newCases: cycleCase.id === cycleCaseId ? cycleCase.newCases + 1 : cycleCase.newCases
    }));
  }
  dispatch({
    type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
    payload: {
      cases,
      additionalCases
    }
  });
};

export const clearCases = () => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const cases = state.cases?.map(cycleCase => ({
    ...cycleCase,
    newCases: 0
  }));
  const additionalCases = state.additionalCases?.map(cycleCase => ({
    ...cycleCase,
    newCases: 0
  }));
  dispatch({
    type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
    payload: {
      cases,
      additionalCases
    }
  });
};

export const expandCase = (payload) => (dispatch, getState) => {
  const { cycleCaseId, isAdditional } = payload;
  const state = getState().studentCycleOverview;
  let cases, additionalCases;
  if (isAdditional) {
    additionalCases = state.additionalCases?.map(cycleCase => ({
      ...cycleCase,
      isExpanded: cycleCase.id === cycleCaseId ? !cycleCase.isExpanded : cycleCase.isExpanded
    }));
  } else {
    cases = state.cases?.map(cycleCase => ({
      ...cycleCase,
      isExpanded: cycleCase.id === cycleCaseId ? !cycleCase.isExpanded : cycleCase.isExpanded
    }));
  }
  dispatch({
    type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
    payload: {
      cases,
      additionalCases
    }
  });
};

export const openCaseRegisterDialog = (payload) => (dispatch, getState) => {
  const { localizationService } = payload;
  const state = getState().studentCycleOverview;
  const { availableDepartments } = state;
  const latestEntry = getLatestEntry(state);
  let department = getDefaultDepartment(availableDepartments);
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
    payload: {
      editDialogTitle: localizationService.toLanguageString('studentCycle.caseConfirmDialogTitle'),
      editDialogType: DialogTypes.REGISTER_CASE,
      editDialogConfirmButtonText: localizationService.toLanguageString('buttons.register'),
      editDialogForm: {
        department,
        manager: latestEntry && {
          id: latestEntry.managerUserId,
          name: latestEntry.managerUserFullName
        },
        medicalHistoryNumber: latestEntry?.medicalHistoryNumber,
        date: new Date()
      },
      editDialogValidationResult: null,
      editDialogError: null
    }
  });
};

export const registerCases = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_BEGIN });
  const state = getState().studentCycleOverview;
  const { department, manager, showMentorFields, stampNumber, name, surname, medicalHistoryNumber, date, localizationService, studentId, email } = payload;
  const baseData = {
    date,
    medicalHistoryNumber,
    studyPlanCycleDepartmentId: department.id,
    managerUserId: manager?.id
  };
  const apiModel = [];
  const pushCaseItems = (itemsCount, cycleCaseId, studyPlanCycleStudentId, isAdditional, studyPlanCycleStudentCaseId) => {
    for (let i = 0; i < itemsCount; i++) {
      apiModel.push({
        ...baseData,
        cycleCaseId,
        studyPlanCycleStudentId,
        isAdditional,
        studyPlanCycleStudentCaseId
      });
    }
  };
  state.cases?.filter(cycleCase => cycleCase.newCases > 0)
    .forEach(cycleCase => pushCaseItems(cycleCase.newCases, cycleCase.id, state.studyPlanCycleStudentId));
  state.additionalCases?.filter(cycleCase => cycleCase.newCases > 0)
    .forEach(cycleCase => pushCaseItems(cycleCase.newCases, cycleCase.cycleCaseId, cycleCase.studyPlanCycleStudentId, true, cycleCase.id));
  try {
    if (showMentorFields) {
      const roles = [RoleTypes.MENTOR];
      const newMentorId = await api.post('api/users', { roles, stampNumber, name, surname, email, isExternal: true });
      for (let i = 0; i < apiModel.length; i++) {
        apiModel[i].managerUserId = newMentorId;
      }
    }
    const response = await api.post(`api/students/${studentId}/cycles/${state.studyPlanCycleStudentId}/cases`, apiModel);
    let successCount = 0;
    let errorCount = 0;
    response?.forEach(item => {
      if (item.statusCode === 201) {
        successCount++;
      } else {
        errorCount++;
      }
    });
    if (errorCount > 0) {
      const messageTemplate = localizationService.toLanguageString('studentCycle.caseRegisterErrorMessage');
      const errorMessage = messageTemplate.replace('{0}', errorCount).replace('{1}', errorCount + successCount).replace('{2}', successCount);
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
        payload: {
          editDialogTitle: localizationService.toLanguageString('studentCycle.caseConfirmDialogTitle'),
          editDialogType: DialogTypes.REGISTER_CASE,
          editDialogConfirmButtonText: localizationService.toLanguageString('buttons.register'),
          editDialogForm: state.editDialogForm,
          editDialogValidationResult: null,
          editDialogError: errorMessage,
          editDialogLoading: false
        }
      });
    } else {
      dispatch(loadStudentCases({ applyNewCases: true, studentId }));
      dispatch(closeEditDialog());
    }
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
        payload: {
          editDialogTitle: localizationService.toLanguageString('studentCycle.caseConfirmDialogTitle'),
          editDialogType: DialogTypes.REGISTER_CASE,
          editDialogConfirmButtonText: localizationService.toLanguageString('buttons.register'),
          editDialogForm: state.editDialogForm,
          editDialogValidationResult: {
            errorMessage: error.message,
            errors: error.errors
          },
          editDialogError: null,
          editDialogLoading: false
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_END });
      throw error;
    }
  }
};

export const openCaseEditDialog = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { caseIndex, historyCaseIndex, isAdditional, localizationService } = payload;
  const studentCase = isAdditional
    ? state.additionalCases[caseIndex].history[historyCaseIndex]
    : state.cases[caseIndex].history[historyCaseIndex];
  const department = state.availableDepartments?.find(department => department.id === studentCase.studyPlanCycleDepartmentId);
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
    payload: {
      editDialogTitle: localizationService.toLanguageString('studentCycle.caseEditDialogTitle'),
      editDialogType: DialogTypes.EDIT_CASE,
      editDialogConfirmButtonText: localizationService.toLanguageString('buttons.save'),
      editDialogForm: {
        id: studentCase.id,
        cycleCaseId: studentCase.cycleCaseId,
        level: studentCase.level,
        date: studentCase.date,
        medicalHistoryNumber: studentCase.medicalHistoryNumber,
        studyPlanCycleStudentId: studentCase.studyPlanCycleStudentId,
        department,
        manager: {
          id: studentCase.managerUserId,
          name: studentCase.managerUserFullName
        },
        isAdditional,
        studyPlanCycleStudentCaseId: studentCase.studyPlanCycleStudentCaseId
      },
      editDialogValidationResult: null,
      editDialogError: null
    }
  });
};

export const saveCase = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const { localizationService, studentId, department, manager, showMentorFields, stampNumber, name, surname, email, ...data } = payload;
  const apiModel = {
    ...data,
    studyPlanCycleDepartmentId: department.id,
    managerUserId: manager?.id
  };
  try {
    if (showMentorFields) {
      const roles = [RoleTypes.MENTOR];
      const newMentorId = await api.post('api/users', { roles, stampNumber, name, surname, email, isExternal: true });
      apiModel.managerUserId = newMentorId;
    }
    if (data.isAdditional) {
      await api.put(`api/students/${studentId}/cycles/${data.studyPlanCycleStudentId}/cases/${data.id}`, apiModel);
    } else {
      await api.put(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/cases/${payload.id}`, apiModel);
    }
    dispatch(loadStudentCases({ applyNewCases: false, studentId }));
    dispatch(closeEditDialog());
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG,
        payload: {
          editDialogTitle: localizationService.toLanguageString('studentCycle.caseEditDialogTitle'),
          editDialogType: DialogTypes.EDIT_CASE,
          editDialogConfirmButtonText: localizationService.toLanguageString('buttons.save'),
          editDialogForm: state.editDialogForm,
          editDialogValidationResult: {
            errorMessage: error.message,
            errors: error.errors
          },
          editDialogError: null,
          editDialogLoading: false
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_END });
      throw error;
    }
  }
};

export const openCaseDeleteDialog = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { caseIndex, historyCaseIndex, isAdditional, localizationService } = payload;
  const studentCase = isAdditional
    ? state.additionalCases[caseIndex].history[historyCaseIndex]
    : state.cases[caseIndex].history[historyCaseIndex];
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
    payload: {
      deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.caseDeleteDialogMessage'),
      deleteDialogType: isAdditional ? DialogTypes.DELETE_ADDITIONAL_CASE : DialogTypes.DELETE_CASE,
      deleteDialogItemId: studentCase.id
    }
  });
};

export const deleteSelectedCase = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_BEGIN });
  const state = getState().studentCycleOverview;
  const { deleteDialogItemId } = state;
  const { isAdditional, localizationService, studentId } = payload;
  try {
    let studyPlanCycleStudentId;
    let cases, additionalCases;
    if (isAdditional) {
      additionalCases = state.additionalCases?.map(cycleCase => {
        const history = cycleCase.history?.filter(item => item.id !== deleteDialogItemId);
        if (studyPlanCycleStudentId == null) {
          studyPlanCycleStudentId = cycleCase.history?.find(item => item.id === deleteDialogItemId)?.studyPlanCycleStudentId;
        }
        return {
          ...cycleCase,
          history,
          studentCases: history?.length || 0
        };
      });
    } else {
      cases = state.cases?.map(cycleCase => {
        const history = cycleCase.history?.filter(item => item.id !== deleteDialogItemId);
        return {
          ...cycleCase,
          history,
          studentCases: history?.length || 0
        };
      });
    }
    await api.remove(`api/students/${studentId}/cycles/${isAdditional ? studyPlanCycleStudentId : state.studyPlanCycleStudentId}/cases/${deleteDialogItemId}`);
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
      payload: {
        cases,
        additionalCases
      }
    });
    dispatch(closeDeleteDialog());
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
        payload: {
          deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.caseDeleteDialogMessage'),
          deleteDialogType: DialogTypes.DELETE_CASE,
          deleteDialogItemId: state.deleteDialogItemId,
          deleteDialogErrorMessage: localizationService.toLanguageString('studentCycle.caseDeleteErrorMessage')
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_END });
      throw error;
    }
  }
};

export const closeAddDialog = () => (dispatch) => {
  dispatch({
    type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG
  });
};

export const openAdditionalCycleSkillAddDialog = (payload) => (dispatch) => {
  const { localizationService } = payload;
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG,
    payload: {
      addDialogType: DialogTypes.ADD_SKILL,
      addDialogTitle: localizationService.toLanguageString('studentCycle.addSkillFromAnotherCycle')
    }
  });
};

export const openAdditionalCycleSkillDeleteDialog = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { index, localizationService } = payload;
  const id = state.additionalSkills[index].id;
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
    payload: {
      deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.cycleSkillDeleteDialogMessage'),
      deleteDialogType: DialogTypes.DELETE_CYCLE_SKILL,
      deleteDialogItemId: id
    }
  });
};

export const deleteSelectedAdditionalCycleSkill = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId, deleteDialogItemId } = state;
  const { localizationService, studentId } = payload;
  try {
    await api.remove(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/additional_skills/${deleteDialogItemId}`);
    const additionalSkills = [];
    state.additionalSkills.forEach(skill => {
      if (skill.id === deleteDialogItemId) {
        return;
      }
      additionalSkills.push(skill);
    });
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
      payload: {
        additionalSkills
      }
    });
    dispatch(closeDeleteDialog());
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
        payload: {
          deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.cycleSkillDeleteDialogMessage'),
          deleteDialogType: DialogTypes.DELETE_CYCLE_SKILL,
          deleteDialogItemId: state.deleteDialogItemId,
          deleteDialogErrorMessage: localizationService.toLanguageString('studentCycle.cycleSkillDeleteErrorMessage')
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_END });
      throw error;
    }
  }
};

export const openAdditionalCycleCaseAddDialog = (payload) => (dispatch) => {
  const { localizationService } = payload;
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG,
    payload: {
      addDialogType: DialogTypes.ADD_CASE,
      addDialogTitle: localizationService.toLanguageString('studentCycle.addCaseFromAnotherCycle')
    }
  });
};

export const saveAdditionalCycleCase = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const apiModel = {
    additionalStudyPlanCycleStudentId: studyPlanCycleStudentId,
    studyPlanCycleStudentId: payload.studyPlanCycleStudent.id,
    cycleCaseId: payload.case.id
  };
  try {
    await api.post(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/additional_cases`, apiModel);
    dispatch(loadAdditionalCycleCases({ studentId: payload.studentId }));
    dispatch(closeAddDialog());
  } catch (error) {
    dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_END });
    throw error;
  }
};

export const openAdditionalCycleCaseDeleteDialog = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const { index, localizationService } = payload;
  const cycleCaseId = state.additionalCases[index].id;
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
    payload: {
      deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.cycleCaseDeleteDialogMessage'),
      deleteDialogType: DialogTypes.DELETE_CYCLE_CASE,
      deleteDialogItemId: cycleCaseId
    }
  });
};

export const deleteSelectedAdditionalCycleCase = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId, deleteDialogItemId } = state;
  const { localizationService, studentId } = payload;
  try {
    await api.remove(`api/students/${studentId}/cycles/${studyPlanCycleStudentId}/additional_cases/${deleteDialogItemId}`);
    const additionalCases = [];
    state.additionalCases.forEach(cycleCase => {
      if (cycleCase.id === deleteDialogItemId) {
        return;
      }
      additionalCases.push(cycleCase);
    });
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
      payload: {
        additionalCases
      }
    });
    dispatch(closeDeleteDialog());
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG,
        payload: {
          deleteDialogConfirmationMessage: localizationService.toLanguageString('studentCycle.cycleCaseDeleteDialogMessage'),
          deleteDialogType: DialogTypes.DELETE_CYCLE_CASE,
          deleteDialogItemId: state.deleteDialogItemId,
          deleteDialogErrorMessage: localizationService.toLanguageString('studentCycle.cycleCaseDeleteErrorMessage')
        }
      });
    } else {
      dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_END });
      throw error;
    }
  }
};

export const filterAvailableCycles = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const availableCycles = payload?.keyword
    ? state.availableCycles.filter(cycle => cycle.name?.toLowerCase().includes(payload.keyword?.toLowerCase()))
    : state.availableCycles;
  dispatch({
    type: StudentCycleOverviewActionTypes.FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLES,
    payload: {
      availableCycles
    }
  });
};

export const loadAvailableCycleSkills = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_START });
  const state = getState().studentCycleOverview;
  const { value } = payload;
  if (value?.cycleId != null) {
    try {
      state.studentCycleOverviewCancelToken?.cancel();
      state.studentCycleOverviewCancelToken = axios.CancelToken.source();
      const existingAdditionalSkillIds = state.additionalSkills?.map(skill => skill.cycleSkillAbilityId) ?? [];
      const cycleSkills = await api.get(`api/cycles/${value.cycleId}/skill_abilities`, null, state.studentCycleOverviewCancelToken.token);
      const availableCycleSkills = cycleSkills?.data
        .filter(skill => !existingAdditionalSkillIds.includes(skill.id))
        .map(skill => ({
          id: skill.id,
          name: skill.studyProgramSkillAbilityName
        }));
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_END,
        payload: {
          availableCycleSkills
        }
      });
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  } else {
    dispatch({
      type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_END,
      payload: {
        availableCycleSkills: []
      }
    });
  }
};

export const loadAvailableCycleCases = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_START });
  const state = getState().studentCycleOverview;
  const { value } = payload;
  if (value?.cycleId != null) {
    try {
      state.studentCycleOverviewCancelToken?.cancel();
      state.studentCycleOverviewCancelToken = axios.CancelToken.source();
      const existingAdditionalCaseIds = state.additionalCases?.map(cycleCase => cycleCase.cycleCaseId) ?? [];
      const cycleCases = await api.get(`api/cycles/${value.cycleId}/cases`, null, state.studentCycleOverviewCancelToken.token);
      const availableCycleCases = cycleCases?.data
        .filter(cycleCase => !existingAdditionalCaseIds.includes(cycleCase.id))
        .map(cycleCase => ({
          id: cycleCase.id,
          name: cycleCase.studyProgramCaseName
        }));
      dispatch({
        type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_END,
        payload: {
          availableCycleCases
        }
      });
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  } else {
    dispatch({
      type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_END,
      payload: {
        availableCycleCases: []
      }
    });
  }
};

export const saveAdditionalCycleSkill = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_BEGIN });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  const apiModel = {
    additionalStudyPlanCycleStudentId: studyPlanCycleStudentId,
    studyPlanCycleStudentId: payload.studyPlanCycleStudent.id,
    cycleSkillAbilityId: payload.skill.id
  };
  try {
    await api.post(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/additional_skills`, apiModel);
    dispatch(loadAdditionalCycleSkills({ studentId: payload.studentId }));
    dispatch(closeAddDialog());
  } catch (error) {
    dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_END });
    throw error;
  }
};

export const loadAdditionalCycleSkills = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_START });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    const [additionalCycleSkills, studentSkills] = await Promise.all([
      api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/additional_skills`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/skills`, null, state.studentCycleOverviewCancelToken.token)
    ]);
    const expandedAdditionalSkillIds = state.additionalSkills?.filter(item => item.isExpanded).map(item => item.id);
    const additionalSkills = additionalCycleSkills?.data
      .map(skill => {
        const history = studentSkills?.data.filter(item => item.cycleSkillAbilityId === skill.cycleSkillAbilityId)
          .map(item => ({
            ...item,
            lastChangedOn: new Date(item.lastChangedOn),
            date: new Date(item.date)
          }))
          .sort(compareLevelsAndDates);
        return {
          id: skill.id,
          cycleSkillAbilityId: skill.cycleSkillAbilityId,
          additionalStudyPlanCycleStudentId: skill.additionalStudyPlanCycleStudentId,
          studyPlanCycleStudentId: skill.studyPlanCycleStudentId,
          name: skill.cycleSkillAbilityName,
          cycleName: skill.cycleName,
          levelA: history?.filter(item => item.level === 'A').length || 0,
          requiredA: skill.minRequiredLevelA,
          levelB: history?.filter(item => item.level === 'B').length || 0,
          requiredB: skill.minRequiredLevelB,
          levelC: history?.filter(item => item.level === 'C').length || 0,
          requiredC: skill.minRequiredLevelC,
          newLevelA: 0,
          newLevelB: 0,
          newLevelC: 0,
          isExpanded: expandedAdditionalSkillIds?.includes(skill.id) || false,
          history
        };
      });
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS,
      payload: {
        additionalSkills
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const loadAdditionalCycleCases = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_START });
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    const [additionalCycleCases, studentCases] = await Promise.all([
      api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/additional_cases`, null, state.studentCycleOverviewCancelToken.token),
      api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/cases`, null, state.studentCycleOverviewCancelToken.token)
    ]);
    const expandedAdditionalCaseIds = state.additionalCases?.filter(item => item.isExpanded).map(item => item.id);
    const additionalCases = additionalCycleCases?.data
      .map(cycleCase => {
        const history = studentCases?.data.filter(item => item.cycleCaseId === cycleCase.cycleCaseId)
          .map(item => ({
            ...item,
            lastChangedOn: new Date(item.lastChangedOn),
            date: new Date(item.date)
          }))
          .sort(compareDates);
        return {
          id: cycleCase.id,
          cycleCaseId: cycleCase.cycleCaseId,
          name: cycleCase.cycleCaseName,
          cycleName: cycleCase.cycleName,
          additionalStudyPlanCycleStudentId: cycleCase.additionalStudyPlanCycleStudentId,
          studyPlanCycleStudentId: cycleCase.studyPlanCycleStudentId,
          studentCases: history?.length || 0,
          required: cycleCase.minRequiredNumber,
          newCases: 0,
          isExpanded: expandedAdditionalCaseIds?.includes(cycleCase.id) || false,
          diagnoses: cycleCase.diagnoses?.sort().join(', '),
          history
        };
      });
    dispatch({
      type: StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES,
      payload: {
        additionalCases
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const completeStudentCycleTheoreticalPart = () => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studyPlanId, studyPlanCycleStudentId } = state;
  const { studyPlanCycleId, id } = state.selectedTheoreticalPart;
  dispatch({ type: StudentCycleOverviewActionTypes.COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_START });
  try {
    await api.post(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/theoretical_parts/${id}/complete`);
    dispatch({ type: StudentCycleOverviewActionTypes.COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_END });
  } catch (error) {
    dispatch({ type: StudentCycleOverviewActionTypes.COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_END });
    throw error;
  }
  dispatch(closeRegisterTheoreticalPartDialog());
  dispatch(loadStudentCycleTheoreticalParts({ reload: true }));
};

export const getCompletionStats = () => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  const managersEvaluated = state.managerSurveys?.every(manager => manager.evaluation !== null);
  const cycleSurveyEvaluated = state.cycleSurvey !== null;
  return {
    skillsCompleted: getSkillsCompletion(state.skills),
    casesCompleted: getCasesCompletion(state.cases),
    canSubmit: managersEvaluated && cycleSurveyEvaluated
  };
};

export const openCycleConfirmSubmitDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG });
};

export const closeCycleConfirmSubmitDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG });
};

export const submitCycle = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const { studyPlanCycleStudentId } = state;
  try {
    await api.post(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/actions/submit`);
    const [cycle, cycleStatusHistory] = await Promise.all([
      api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}`),
      api.get(`api/students/${payload.studentId}/cycles/${studyPlanCycleStudentId}/status_history`)
    ]);
    dispatch({
      type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG,
      payload: {
        readOnly: isCycleReadOnly(cycle.status),
        cycle,
        cycleStatusHistory
      }
    });
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG,
        payload: {
          confirmSubmitDialogErrorMessage: error.errors ? Object.keys(error.errors).map(key => error.errors[key]).join(', ') : error.message
        }
      });
    } else {
      throw error;
    }
  }
};

export const expandCycleStatusHistory = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.TOGGLE_STUDENT_CYCLE_OVERVIEW_CYCLE_STATUS_HISTORY_EXPANDED });
};

export const closeCycleStatusHistory = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_STATUS_HISTORY });
};

export const openRegisterTheoreticalPartDialog = (payload) => (dispatch) => {
  dispatch({
    type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_SUBMIT_DIALOG,
    payload: payload
  });
};

export const closeRegisterTheoreticalPartDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_SUBMIT_DIALOG });
};

export const clearStudentCycleOverview = (payload) => (dispatch, getState) => {
  const state = getState().studentCycleOverview;
  dispatch({
    type: StudentCycleOverviewActionTypes.CLEAR_STUDENT_CYCLE_OVERVIEW,
    payload: payload.shouldPreserveSelectedTab ? { selectedTab: state.selectedTab } : {}
  });
};

export const submitApprovalRequest = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_START });
  const state = getState().studentCycleOverview;
  const { studentId, studyPlanId, studyPlanCycleId, studyPlanCycleStudentId } = payload;
  const apiModel = {
    studyPlanCycleStudentId,
    managerUserId: state.selectedManagerForApprovalRequestUser.managerUserId
  };
  try {
    state.studentCycleOverviewCancelToken?.cancel();
    state.studentCycleOverviewCancelToken = axios.CancelToken.source();
    await api.post(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}`, apiModel, state.studentCycleOverviewCancelToken.token);
    dispatch({
      type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_END,
      payload: { studentCycleCompletionLoaded: false }
    });
    dispatch(closeConfirmApprovalRequestDialog());
    dispatch(loadStudentCycleCompletion({ studentId, studyPlanId, studyPlanCycleId, studyPlanCycleStudentId }));
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_END,
        payload: { confirmApprovalRequestDialogErrorMessage: error.errors ? Object.keys(error.errors).map(key => error.errors[key]).join(', ') : error.message }
      });
    } else {
      throw error;
    }
  }
};

export const openConfirmApprovalRequestDialog = (payload) => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_APPROVAL_REQUEST_CONFIRM_DIALOG, payload });
};

export const closeConfirmApprovalRequestDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_APPROVAL_REQUEST_CONFIRM_DIALOG });
};

export const submitStudyPlanCycleDepartmentStudentDesignation = () => async(dispatch, getState, { api }) => {
  const state = getState().studentCycleOverview;
  const studyPlanCycleDepartmentStudentId = state.selectedStudyPlanCycleDepartmentStudentIdForDesignation;
  dispatch({ type: StudentCycleOverviewActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_START });
  try {
    const designationId = await api.post(`api/study_plan_cycle_department_student/${studyPlanCycleDepartmentStudentId}/designation_submit`);
    dispatch({
      type: StudentCycleOverviewActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END,
      payload: {
        availableDepartments: state.availableDepartments.map(element => {
          if (element.studyPlanCycleDepartmentStudentId === studyPlanCycleDepartmentStudentId) {
            return { ...element,
              designationStatus: DESIGNATION_STATUS.SUBMITTED,
              designationId: designationId
            };
          }
          return element;
        })
      }
    });
    dispatch(closeSubmitDepartmentDesignationDialog());
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCycleOverviewActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END });
      throw error;
    }
  }
};

export const openSubmitDepartmentDesignationDialog = (payload) => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DEPARTMENT_DESIGNATION_SUBMIT_DIALOG, payload });
};

export const closeSubmitDepartmentDesignationDialog = () => (dispatch) => {
  dispatch({ type: StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_DEPARTMENT_DESIGNATION_SUBMIT_DIALOG });
};