import * as React from 'react';
import styled, { css } from 'styled-components';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';
import { GridLayout } from '../layouts';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const ValueContainer = styled(GridLayout).attrs(props => ({
  columns: 'auto 24px',
  columnGap: '4px'
}))`
  align-items: center;
`;

const DropDown = props => {
  const { valid, validationMessage, label, ...rest } = props;
  const dropDownList = (
    <DropDownList
      {...rest}
      valid={valid}
      valueRender={element => <ValueContainer>{element}</ValueContainer>}
    />
  );

  return (
    <Container $width={props.width} className={'k-form-fieldset'}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={props.value}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {dropDownList}
        </FloatingLabel>
        : dropDownList
      }
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default DropDown;