import React from 'react';
import { connect } from 'react-redux';
import {
  provideIntlService,
  provideLocalizationService,
  registerForIntl,
  registerForLocalization
} from '@progress/kendo-react-intl';
import { Grid, InlineBadge } from '../../shared/components';
import { withRouter } from '../../../components/withRouter';
import {
  findDesignations,
  loadDesignationListPage,
  loadDesignationStatuses,
  sortDesignations,
  selectDesignation,
  selectAllDesignations,
  approveSelectedDesignations,
  toggleConfirmDialog,
  signSelectedDesignations
} from '../actions/designation-list-page-actions';
import { Colors, Text } from '../../../ui';
import { StackLayout } from '@progress/kendo-react-layout';
import { getDesignationStatusColor } from '../../../utils/designationStatusColors';
import { DatePeriodFormatter } from '../../shared/components/Formatter';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ROLE_PERMISSIONS } from '../../../resources/rolePermissions';
import { DESIGNATION_STATUS } from '../../../resources/designationStatus';

class DesignationList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.renderResident = this.renderResident.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleApproveClick = this.handleApproveClick.bind(this);
    this.openConfirmDialog = this.openConfirmDialog.bind(this);
    this.handleSignClick = this.handleSignClick.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadDesignationListPage({ localizationService: localizationService });
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.designations}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSort}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple'
          }}
          dataItemKey='id'
          selectedField='isSelected'
          onSelectionChange={this.handleSelect}
          onHeaderSelectionChange={this.handleSelectAll}
          columns={[
            {
              field: 'isSelected',
              width: '50px',
              headerCell: () => (
                <div>
                  <Checkbox checked={this.props.selectedAll} onClick={this.handleSelectAll}></Checkbox>
                </div>
              )
            },
            {
              field: 'resident',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('designations.resident'),
              cell: this.renderResident
            },
            {
              field: 'status',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('designations.status'),
              cell: this.renderStatus
            }
          ]}
        >
          <GridToolbar>
            <Button
              themeColor='primary'
              onClick={() => this.openConfirmDialog(localizationService, this.handleApproveClick, DESIGNATION_STATUS.SUBMITTED)}
              disabled={!this.props.selectedDesignations || this.props.selectedDesignations?.length === 0}
            >
              {localizationService.toLanguageString('buttons.confirm')}
            </Button>
            {this.props.userPermissions?.includes(ROLE_PERMISSIONS.PERFORM_DEAN_ACTIONS) &&
              <Button
                themeColor='primary'
                onClick={() => this.openConfirmDialog(localizationService, this.handleSignClick, DESIGNATION_STATUS.APPROVED)}
                disabled={!this.props.selectedDesignations || this.props.selectedDesignations?.length === 0}
              >
                {localizationService.toLanguageString('buttons.sign')}
              </Button>
            }
          </GridToolbar>
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.toggleConfirmDialog}>
            <Text>
              {this.props.confirmText}
            </Text>
            <DialogActionsBar>
              <Button
                onClick={this.props.confirmFunction}
                themeColor='primary'
                disabled={this.props.isConfirmButtonDisabled}
              >
                {localizationService.toLanguageString('buttons.confirm')}
              </Button>
              <Button
                onClick={this.props.toggleConfirmDialog}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderResident(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    const cycleText = `${localizationService.toLanguageString('designations.cycle')}: ${dataItem.cycle}`;
    const creditsText = `${localizationService.toLanguageString('designations.credits')}: ${dataItem.accreditationCredits}`;
    const datePeriodText = DatePeriodFormatter(dataItem.accreditationDateFrom, dataItem.accreditationDateTo, intlService);
    return (
      <td className={className} onClick={() => this.handleRowClick(e.dataItem)}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {`${dataItem.student}, ${dataItem.studyProgram}`}
          <Text textColor={Colors.GRAY_50} variant='caption'>
            {dataItem.department}
          </Text>
          <Text textColor={Colors.GRAY_50} variant='caption'>
            {`${cycleText}, ${creditsText}, ${datePeriodText}`}
          </Text>
        </StackLayout>
      </td>
    );
  }

  renderStatus(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className} onClick={() => this.handleRowClick(e.dataItem)}>
        {dataItem.status &&
          <InlineBadge themeColor={getDesignationStatusColor(dataItem.status)}>
            {localizationService.toLanguageString('designationStatus.' + dataItem.status)}
          </InlineBadge>
        }
      </td>
    );
  }

  async handlePageChange(e) {
    await this.props.findDesignations({ skip: e.page.skip, take: e.page.take });
  }

  handleSort(e) {
    this.props.sortDesignations(e.sort);
  }

  handleRowClick(e) {
    this.props.navigate(`/designations/${e.id}/overview`);
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectDesignation(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllDesignations();
  }

  handleApproveClick() {
    this.props.approveSelectedDesignations();
  }

  openConfirmDialog(localizationService, confirmFunction, statusForValidation) {
    this.props.toggleConfirmDialog({ localizationService, confirmFunction, statusForValidation });
  }

  async handleSignClick() {
    await this.props.signSelectedDesignations();
    if (this.props.signingRedirectUrl) {
      window.open(this.props.signingRedirectUrl, '_self');
    }
  }
}

const mapStateToProps = state => ({
  userPermissions: state.app.userPermissions,
  loading: state.designationListPage.loading,
  designations: state.designationListPage.designations,
  skip: state.designationListPage.skip,
  take: state.designationListPage.take,
  total: state.designationListPage.total,
  sorting: state.designationListPage.sorting,
  selectedDesignations: state.designationListPage.selectedDesignations,
  selectedAll: state.designationListPage.selectedAll,
  isConfirmDialogVisible: state.designationListPage.isConfirmDialogVisible,
  signingRedirectUrl: state.designationListPage.signingRedirectUrl,
  confirmText: state.designationListPage.confirmText,
  confirmFunction: state.designationListPage.confirmFunction,
  isConfirmButtonDisabled: state.designationListPage.isConfirmButtonDisabled
});

const mapDispatchToProps = dispatch => ({
  loadDesignationListPage: (payload) => dispatch(loadDesignationListPage(payload)),
  findDesignations: (payload) => dispatch(findDesignations(payload)),
  sortDesignations: (payload) => dispatch(sortDesignations(payload)),
  loadDesignationStatuses: (payload) => dispatch(loadDesignationStatuses(payload)),
  selectDesignation: (payload) => dispatch(selectDesignation(payload)),
  selectAllDesignations: () => dispatch(selectAllDesignations()),
  approveSelectedDesignations: () => dispatch(approveSelectedDesignations()),
  toggleConfirmDialog: (payload) => dispatch(toggleConfirmDialog(payload)),
  signSelectedDesignations: () => dispatch(signSelectedDesignations())
});

registerForLocalization(DesignationList);
registerForIntl(DesignationList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesignationList));