import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import Home from '../../../pages/Home.jsx';
import Profile from '../../../pages/Profile.jsx';
import Settings from '../../../pages/Settings.jsx';
import { CycleListPage,
  CycleOverviewPage,
  CycleFormPage,
  CycleCaseFormPage,
  CycleskillAbilityFormPage,
  CycleTheoreticalPartFormPage,
  CycleCaseOverviewPage,
  CycleCaseDiagnosisList } from '../../cycles/index';
import { CyclePlanListPage } from '../../cycle-plans/index';
import { DepartmentListPage, DepartmentOverviewPage, DepartmentFormPage } from '../../departments';
import { StudentListPage, StudentOverviewPage, StudentCompetencyOverviewPage, StudentCompetencySurveyOverviewPage, StudentCycleOverviewPage } from '../../students';
import {
  StudyPlanListPage,
  StudyPlanOverviewPage,
  StudyPlanFormPage,
  StudyPlanCycleOverviewPage,
  StudyPlanCycleSelectPage,
  StudyPlanCycleDepartmentFormPage,
  StudyPlanCycleStudentSelectPage,
  StudyPlanCycleStudentFormPage,
  StudyPlanCycleTheoreticalPartFormPage,
  StudyPlanCycleStudentOverviewPage
} from '../../study-plans';
import { StudyProgramListPage, StudyProgramOverviewPage, StudyProgramFormPage, StudyProgramCaseFormPage, StudyProgramSkillAbilityFormPage, StudyProgramTheoreticalPartFormPage } from '../../study-programs';
import { UserListPage, UserFormPage, UserSeachDialog } from '../../users';
import { TheoreticalPartListPage, TheoreticalPartOverviewPage } from '../../theoretical-parts';
import { MyStudiesStudentOverviewPage, MyStudiesStudentCycleOverviewPage, MyStudiesSurveyFormPage } from '../../my-studies';
import { ManagedStudentListPage, ManagedStudentOverviewPage } from '../../managed-students';
import {
  CompetencyListPage,
  CompetencyFormPage,
  CompetencyOverviewPage,
  CompetencySurveyOverviewPage,
  CompetencySurveyFormPage,
  CompetencySurveyQuestionFormPage,
  CompetencyCycleSelectPage,
  SubcompetencyListPage,
  SubcompetencyFormPage,
  CompetencySubcompetencySelectPage,
  SurveyListPage
} from '../../competencies';
import { MyCompetencyListPage, MyCompetencyOverviewPage, MyCompetencySurveyOverviewPage } from '../../my-competencies';
import { MySurveyListPage, MySurveyFormPage } from '../../my-surveys';
import { CompetencyRequestListPage, CompetencyRequestSummaryPage } from '../../competency-requests';
import { UserOverviewPage } from '../../users';
import { ApprovalRequestListPage } from '../../approval-requests';

import DrawerRouterContainer from '../../../ui/components/application/DrawerRouterContainer';
import { loadApplication, deauthorize, showError, handleStudyProgramChange, updateUserMenuItems, updateUserDrawerItems, logoutFromImpersonate, fetchRequestsData } from '../actions/app-page-actions';
import { Loader } from '../../shared/components';
import { Error, Forbidden } from './index';
import { withRouter } from '../../../components/withRouter';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import NotificationsListPage from '../../notifications/components/notifications-list-page.jsx';
import NotificationFormPage from '../../notifications/components/notification-form-page.jsx';
import MyNotificationsListPage from '../../my-notifications/components/my-notifications-list-page.jsx';
import { DesignationOverviewPage, DesignationListPage } from '../../designations/components/index.js';

class App extends React.PureComponent {
  constructor() {
    super();
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogoutFromImpersonate = this.handleLogoutFromImpersonate.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleNotificationsClick = this.handleNotificationsClick.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('error', this.handleError);
    window.addEventListener('unhandledrejection', this.handleError);
    const localizationService = provideLocalizationService(this);
    await this.props.loadApplication({ localizationService });
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleError);
    window.removeEventListener('unhandledrejection', this.handleError);
  }

  async componentDidCatch(error, data) {
    this.handleError(error, data);
  }

  render() {
    const {
      loading,
      userId,
      userEmail,
      userInitials,
      userMenuItems,
      userStudyPrograms,
      isError,
      handleStudyProgramChange,
      currentStudies,
      isUserImpersonated,
      onLocaleChange,
      userIsAdministrator
    } = this.props;
    return (
      <>
        {isError &&
          <Error
            messageResource={this.props.errorMessageResource}
            buttonMessageResource={this.props.errorMessageButtonTextResource}
            buttonMessageText={this.props.errorMessageButtonText}
            messageText={this.props.errorMessage}
            onButtonClick={this.handleLogout}
          />
        }
        {!isError && loading &&
          <Loader />
        }
        {!isError && !loading &&
          <DrawerRouterContainer
            userId={userId}
            userEmail={userEmail}
            userInitials={userInitials}
            userMenuItems={userMenuItems}
            onUserMenuItemsUpdated={this.props.updateUserMenuItems}
            userStudyPrograms={userStudyPrograms}
            userDrawerItems={this.props.userDrawerItems}
            onUserDrawerItemsUpdated={this.props.updateUserDrawerItems}
            handleStudyProgramChange={handleStudyProgramChange}
            currentStudies={currentStudies}
            isUserImpersonated={isUserImpersonated}
            onLogoutFromImpersonateClick={this.handleLogoutFromImpersonate}
            onLocaleChange={onLocaleChange}
            userIsAdministrator={userIsAdministrator}
            cycleEvaluationRequestCount={this.props.requestsHubData.cycleEvaluationRequestCount}
            surveyRequestCount={this.props.requestsHubData.surveyRequestCount}
            competencyRequestCount={this.props.requestsHubData.competencyRequestCount}
            studentCycleCaseAndSkillApprovalRequestCount={this.props.requestsHubData.studentCycleCaseAndSkillApprovalRequestCount}
            unreadNotificationsCount={this.props.requestsHubData.unreadNotificationsCount}
            onNotificationsClick={this.handleNotificationsClick}
            onFetchRequestsData={this.props.fetchRequestsData}
          >
            {this.renderRoutes()}
            <UserSeachDialog/>
          </DrawerRouterContainer>
        }
      </>
    );
  }

  renderRoutes() {
    return (
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/settings' element={<Settings/>} />
        <Route path='/error' element={<Error/>} />
        <Route path='/forbidden' element={<Forbidden/>} />

        <Route path='/departments' element={<DepartmentListPage/>} />
        <Route path='/departments/:departmentId/overview' element={<DepartmentOverviewPage/>} />
        <Route path='/departments/:departmentId/' element={<DepartmentFormPage/>} />
        <Route path='/departments/create' element={<DepartmentFormPage/>} />

        <Route path='/study_programs' element={<StudyProgramListPage/>} />
        <Route path='/study_programs/:studyProgramId/cases/:studyProgramCaseId' element={<StudyProgramCaseFormPage/>} />
        <Route path='/study_programs/:studyProgramId/skill_abilities/:studyProgramSkillAbilityId' element={<StudyProgramSkillAbilityFormPage/>} />
        <Route path='/study_programs/:studyProgramId/theoretical_parts/:studyProgramTheoreticalPartId' element={<StudyProgramTheoreticalPartFormPage/>} />
        <Route path='/study_programs/:studyProgramId/overview' element={<StudyProgramOverviewPage/>} />
        <Route path='/study_programs/:studyProgramId/' element={<StudyProgramFormPage/>} />
        <Route path='/study_programs/create' element={<StudyProgramFormPage/>} />

        <Route path='/study_plans' element={<StudyPlanListPage/>} />
        <Route path='/study_plans/:studyPlanId/overview' element={<StudyPlanOverviewPage/>} />
        <Route path='/study_plans/:studyPlanId/' element={<StudyPlanFormPage/>} />
        <Route path='/study_plans/create' element={<StudyPlanFormPage/>} />
        <Route path='/study_plans/:studyPlanId/copy' element={<StudyPlanFormPage copy/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/overview' element={<StudyPlanCycleOverviewPage/>} />
        <Route path='/study_plans/:studyPlanId/cycle_select' element={<StudyPlanCycleSelectPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/departments/:studyPlanCycleDepartmentId' element={<StudyPlanCycleDepartmentFormPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/departments/create' element={<StudyPlanCycleDepartmentFormPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/student_select' element={<StudyPlanCycleStudentSelectPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/students/:studyPlanCycleStudentId' element={<StudyPlanCycleStudentOverviewPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/students/:studyPlanCycleStudentId/departments/create' element={<StudyPlanCycleStudentFormPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/students/:studyPlanCycleStudentId/departments/:studyPlanCycleDepartmentStudentId' element={<StudyPlanCycleStudentFormPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/students/:studyPlanCycleStudentId/departments/:studyPlanCycleDepartmentStudentId/designations/:designationId/overview' element={<DesignationOverviewPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/students' element={<StudyPlanCycleStudentFormPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/theoretical_parts/:studyPlanCycleTheoreticalPartId' element={<StudyPlanCycleTheoreticalPartFormPage/>} />
        <Route path='/study_plans/:studyPlanId/cycles/:studyPlanCycleId/theoretical_parts/create' element={<StudyPlanCycleTheoreticalPartFormPage/>} />

        <Route path='/cycles' element={<CycleListPage/>} />
        <Route path='/cycles/:cycleId/overview' element={<CycleOverviewPage/>} />
        <Route path='/cycles/:cycleId/' element={<CycleFormPage/>} />
        <Route path='/cycles/create' element={<CycleFormPage/>} />
        <Route path='/cycles/:cycleId/copy' element={<CycleFormPage copy/>} />
        <Route path='/cycles/:cycleId/cases/create' element={<CycleCaseFormPage/>} />
        <Route path='/cycles/:cycleId/cases/:cycleCaseId' element={<CycleCaseFormPage/>} />
        <Route path='/cycles/:cycleId/cases/:cycleCaseId/overview' element={<CycleCaseOverviewPage/>} />
        <Route path='/cycles/:cycleId/cases/:cycleCaseId/diagnosis' element={<CycleCaseDiagnosisList/>} />
        <Route path='/cycles/:cycleId/skill_abilities/create' element={<CycleskillAbilityFormPage/>} />
        <Route path='/cycles/:cycleId/skill_abilities/:cycleSkillAbilityId' element={<CycleskillAbilityFormPage/>} />
        <Route path='/cycles/:cycleId/theoretical_parts/:cycleTheoreticalPartId' element={<CycleTheoreticalPartFormPage/>} />

        <Route path='/cycle_plans' element={<CyclePlanListPage/>} />

        <Route path='/students' element={<StudentListPage/>} />
        <Route path='/students/:studentId/overview' element={<StudentOverviewPage/>} />
        <Route path='/students/:studentId/competencies/:competencyId/overview' element={<StudentCompetencyOverviewPage/>} />
        <Route path='/students/:studentId/competencies/:competencyId/surveys/:competencySurveyId/overview' element={<StudentCompetencySurveyOverviewPage/>} />
        <Route path='/students/:studentId/cycles/:studyPlanCycleStudentId/overview' element={<StudentCycleOverviewPage/>} />

        <Route path='/theoretical_parts' element={<TheoreticalPartListPage/>} />
        <Route path='/theoretical_parts/:theoreticalPartId/overview' element={<TheoreticalPartOverviewPage/>} />

        <Route path='/users' element={<UserListPage/>} />
        <Route path='/users/:userId/overview' element={<UserOverviewPage/>} />
        <Route path='/users/:userId' element={<UserFormPage/>} />
        <Route path='/users/create' element={<UserFormPage/>} />

        <Route path='/my_studies' element={<MyStudiesStudentOverviewPage/>} />
        <Route path='/my_studies/cycle/:studyPlanCycleStudentId' element={<MyStudiesStudentCycleOverviewPage/>} />
        <Route path='/my_studies/:studyPlanCycleStudentId/surveys/:surveyType/department/:departmentId' element={<MyStudiesSurveyFormPage/>} />
        <Route path='/my_studies/:studyPlanCycleStudentId/surveys/:surveyType/manager/:managerUserId' element={<MyStudiesSurveyFormPage/>} />
        <Route path='/my_studies/:studyPlanCycleStudentId/surveys/:surveyType/preview_survey/:surveyAnswerStudentId' element={<MyStudiesSurveyFormPage preview/>} />

        <Route path='/managed_students' element={<ManagedStudentListPage/>} />
        <Route path='/managed_students/:studyPlanCycleStudentId/overview' element={<ManagedStudentOverviewPage cycleManagerOverview/>} />
        <Route path='/managed_students/:studyPlanCycleStudentId/surveys/:surveyType' element={<MyStudiesSurveyFormPage managersSurvey/>} />

        <Route path='/competencies' element={<CompetencyListPage/>}/>
        <Route path='/competencies/:competencyId/overview' element={<CompetencyOverviewPage/>} />
        <Route path='/competencies/:competencyId' element={<CompetencyFormPage/>}/>
        <Route path='/competencies/create' element={<CompetencyFormPage/>}/>

        <Route path='/competencies/:competencyId/surveys/:competencySurveyId/overview' element={<CompetencySurveyOverviewPage/>} />
        <Route path='/competencies/:competencyId/surveys/:surveyId' element={<CompetencySurveyFormPage/>} />
        <Route path='/competencies/:competencyId/surveys/create' element={<CompetencySurveyFormPage/>} />
        <Route path='/competencies/:competencyId/surveys/:competencySurveyId/questions/:surveyQuestionId' element={<CompetencySurveyQuestionFormPage/>} />
        <Route path='/competencies/:competencyId/surveys/:competencySurveyId/questions/create' element={<CompetencySurveyQuestionFormPage/>} />

        <Route path='/competencies/:competencyId/cycles/cycle_select' element={<CompetencyCycleSelectPage/>} />
        <Route path='/competencies/:competencyId/subcompetencies/subcompetency_select' element={<CompetencySubcompetencySelectPage/>} />

        <Route path='/subcompetencies' element={<SubcompetencyListPage/>} />
        <Route path='/subcompetencies/:subcompetencyId' element={<SubcompetencyFormPage/>} />
        <Route path='/subcompetencies/create' element={<SubcompetencyFormPage />} />

        <Route path='/surveys' element={<SurveyListPage/>} />
        <Route path='/surveys/:surveyId/overview' element={<CompetencySurveyOverviewPage/>} />
        <Route path='/surveys/:surveyId' element={<CompetencySurveyFormPage/>} />
        <Route path='/surveys/create' element={<CompetencySurveyFormPage/>} />
        <Route path='/surveys/:surveyId/questions/create' element={<CompetencySurveyQuestionFormPage/>}/>
        <Route path='/surveys/:surveyId/questions/:surveyQuestionId' element={<CompetencySurveyQuestionFormPage/>}/>

        <Route path='/my_competencies' element={<MyCompetencyListPage/>} />
        <Route path='/my_competencies/:competencyId/overview' element={<MyCompetencyOverviewPage/>} />
        <Route path='/my_competencies/:competencyId/surveys/:competencySurveyId/overview' element={<MyCompetencySurveyOverviewPage/>} />
        <Route path='/my_competencies/:competencyId/surveys/:surveyId/preview_survey/:competencySurveyStudentId' element={<MySurveyFormPage preview myCompetency/>} />

        <Route path='/my_surveys' element={<MySurveyListPage/>} />
        <Route path='/my_surveys/:surveyId/competency_survey_students/:competencySurveyStudentId' element={<MySurveyFormPage/>} />
        <Route path='/my_surveys/:surveyId/competency_survey_students/:competencySurveyStudentId/preview' element={<MySurveyFormPage preview/>} />

        <Route path='/competency_requests' element={<CompetencyRequestListPage/>} />
        <Route path='/competency_requests/:studentCompetencyId' element={<CompetencyRequestSummaryPage/>} />

        <Route path='/approval_requests' element={<ApprovalRequestListPage />} />
        <Route path='/approval_requests/:studyPlanCycleStudentId/overview' element={<ManagedStudentOverviewPage/>} />

        <Route path='/notifications' element={<NotificationsListPage />} />
        <Route path='/notifications/:notificationId' element={<NotificationFormPage />} />
        <Route path='/notifications/create' element={<NotificationFormPage />} />

        <Route path='/my_notifications' element={<MyNotificationsListPage />} />

        <Route path='/designations' element={<DesignationListPage/>}/>
        <Route path='/designations/:designationId/overview' element={<DesignationOverviewPage cycleAdministratorOverview/>} />
      </Routes>
    );
  }

  handleLogout() {
    this.props.deauthorize();
  }

  handleLogoutFromImpersonate() {
    const localizationService = provideLocalizationService(this);
    this.props.logoutFromImpersonate({ localizationService });
  }

  handleReturnHome() {
    this.props.navigate('/');
  }

  handleError(e, data) {
    this.props.showError({ message: e.message, reason: e?.reason });
  }

  handleNotificationsClick() {
    this.props.navigate('/my_notifications');
  }
}

registerForLocalization(App);

const mapStateToProps = state => ({
  loading: state.app.loading,
  userId: state.app.userId,
  userEmail: state.app.userEmail,
  userInitials: state.app.userInitials,
  isUserImpersonated: state.app.isUserImpersonated,
  userIsAdministrator: state.app.userIsAdministrator,
  userPermissions: state.app.userPermissions,
  userMenuItems: state.app.userMenuItems,
  userDrawerItems: state.app.userDrawerItems,
  userStudyPrograms: state.app.userStudyPrograms,
  currentStudies: state.app.currentStudies,
  requestsHubData: state.app.requestsHubData,
  isError: state.app.isError,
  errorMessage: state.app.errorMessage,
  errorMessageResource: state.app.errorMessageResource,
  errorMessageButtonText: state.app.errorMessageButtonText,
  errorMessageButtonTextResource: state.app.errorMessageButtonTextResource
});

const mapDispatchToProps = dispatch => ({
  loadApplication: (payload) => dispatch(loadApplication(payload)),
  deauthorize: () => dispatch(deauthorize()),
  showError: (payload) => dispatch(showError(payload)),
  handleStudyProgramChange: (payload) => dispatch(handleStudyProgramChange(payload)),
  updateUserMenuItems: (payload) => dispatch(updateUserMenuItems(payload)),
  updateUserDrawerItems: (payload) => dispatch(updateUserDrawerItems(payload)),
  logoutFromImpersonate: (payload) => dispatch(logoutFromImpersonate(payload)),
  fetchRequestsData: () => (dispatch(fetchRequestsData()))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App));
