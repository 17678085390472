export default {
  LOAD_DESIGNATION_LIST_START: 'LoadDesignationListStart',
  LOAD_DESIGNATION_LIST_END: 'LoadDesignationListEnd',
  FILTER_DESIGNATION_LIST: 'FilterDesignationList',
  SORT_DESIGNATION_LIST: 'SortDesignationList',
  LOAD_DESIGNATION_LIST_DESIGNATION_STATUSES: 'LoadDesignationListDesignationStatuses',
  SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_START: 'SearchDesignationListFilterStudyProgramStart',
  SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_END: 'SearchDesignationListFilterStudyProgramEnd',
  SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_START: 'SearchDesignationListFilterResidentsStart',
  SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_END: 'SearchDesignationListFilterResidentsEnd',
  SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_START: 'SearchDesignationListFilterDepartmentsStart',
  SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_END: 'SearchDesignationListFilterDepartmentsEnd',
  SELECT_DESIGNATION_LIST: 'SelectDesignationList',
  SELECT_ALL_DESIGNATION_LIST: 'SelectAllDesignationList',
  TOGGLE_DESIGNATION_LIST_CONFIRM_DIALOG: 'ToggleDesignationListConfirmDialog',
  APPROVE_SELECTED_DESIGNATION_START: 'ApproveSelectedDesignationStart',
  APPROVE_SELECTED_DESIGNATION_END: 'ApproveSelectedDesignationEnd',
  SIGN_SELECTED_DESIGNATION_START: 'SignSelectedDesignationStart',
  SIGN_SELECTED_DESIGNATION_END: 'SignSelectedDesignationEnd'
};