import React from 'react';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import styled from 'styled-components';
import { Colors, Text } from '../../../ui';
import { loadCyclePlanListPage } from '../actions/cycle-plan-list-page-actions';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { DateFormatter, EmptyFormatter } from '../../../features/shared/components';
import { randomColor } from 'randomcolor';
import { withRouter } from '../../../components/withRouter';

const DAY_WIDTH = 2;
const RESDIENT_DIV_WIDTH = 270;
const RESDIENT_DIV_PADDING = 10;
const EVENT_START_BORDER_WIDTH = 3;
const RESIDENT_BORDER_WIDTH = 5;
const ROMAN_NUMERICALS = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];
const MONTHS = [7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6];
const MONTH_LENGTHS = year => {
  const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  year && new Date(year + 1, 1, 29).getDate() === 29 && monthLengths.splice(1, 1, 29);
  return monthLengths;
};
const ROW_HEIGHT = 56;
const HEADER_ROW_HEIGHT = 56;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${RESDIENT_DIV_WIDTH}px;
  height: ${HEADER_ROW_HEIGHT}px;
  border-right: 1px solid lightgrey;
  padding-left: ${RESDIENT_DIV_PADDING}px;
`;

const ResidentDiv = styled(Header)`
  border-top: 1px solid lightgrey;
  height: ${ROW_HEIGHT}px;
  width: ${RESDIENT_DIV_WIDTH - RESIDENT_BORDER_WIDTH}px;
  border-left-width: ${RESIDENT_BORDER_WIDTH}px;
  border-left-style: solid;
  border-left-color: ${props => props.valid ? Colors.SUCCESS_40 : Colors.ERROR_40};
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid lightgrey;
  width: ${props => MONTH_LENGTHS(props.year)[props.month] * DAY_WIDTH}px;
  height: ${HEADER_ROW_HEIGHT}px;
`;

const MonthDiv = styled.div`
  width: ${props => MONTH_LENGTHS(props.year)[props.month] * DAY_WIDTH}px;
  height: ${ROW_HEIGHT}px;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
`;

const TableContainer = styled.div`
  border: 1px solid lightgrey;
  border-right: 0;
`;

const RowContainer = styled.div`
  display: flex;
  height: ${ROW_HEIGHT}px;
`;

const HeaderRow = styled(RowContainer)`
  height: ${HEADER_ROW_HEIGHT}px;
`;

const EmptyRow = styled.div`
  display: flex;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  justify-content: center;
  align-items: center;
  height: ${ROW_HEIGHT / 2}px;
`;

const Event = styled.div`
  margin: 9px 0px 9px ${props => props.offset ? props.offset : 0}px;
  width: ${props => props.duration ? props.duration : 0}px;
  height: 38px;
  background-color: ${props => props.color ? props.color : Colors.INFO_20};
  position: ${ props => props.position ?? 'relative'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: ${props => props.intersecting
    ? 'repeating-linear-gradient(-45deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2) 10px, rgba(255, 0, 0, 0.3) 10px, rgba(255, 0, 0, 0.3) 20px)'
    : ''};
  border-left-width: ${EVENT_START_BORDER_WIDTH}px;
  border-left-style: solid;
  border-left-color: ${props => props.borderColor ? props.borderColor : Colors.ERROR_30};
  cursor: pointer;
`;

const EventTitle = styled(Text)`
  padding-left: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
`;

const TooltipContainer = styled(Tooltip)`
  width: 16rem;
`;

const IntersectingIcon = styled.span`
  padding-left: 8px;
  transform: scale(1.3);
  color: ${Colors.INFO_50};
`;

const EventContainer = styled.span`
    position: absolute;
`;

const ResidentInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const GreyText = styled(Text)`
  color: ${Colors.GRAY_50};
`;

const BoldGreyText = styled(Text)`
  color: ${Colors.GRAY_60};
`;

const TooltipItemContainer = styled.span`
  cursor: pointer;
`;

const LinkIcon = styled.span`
  padding-left: 2px;
  padding-bottom: 2px;
  transform: scale(0.65);
`;

class ManagedStudentList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      hoveredStudyEvent: null
    };
    this.tooltipContentTemplate = this.tooltipContentTemplate.bind(this);
    this.handleEventClicked = this.handleEventClicked.bind(this);
    this.handleTooltipClick = this.handleTooltipClick.bind(this);
    this.setHoveredStudyEvent = this.setHoveredStudyEvent.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCyclePlanListPage();
  }

  render() {
    const { cyclePlans, year } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <div>
        <TooltipContainer anchorElement='pointer' position='top' content={(props) => this.tooltipContentTemplate({ ...props, hoveredStudyEvent: this.state.hoveredStudyEvent })}>
          <TableContainer>
            <HeaderRow>
              <Header>
                <Text variant='h6'>{localizationService.toLanguageString('cyclePlan.resident')}</Text>
              </Header>
              {
                MONTHS.map((month, index) => this.renderMonthHeader(month, year, index))
              }
            </HeaderRow>
            {cyclePlans && cyclePlans.length > 0
              ? cyclePlans.map((element, index) => this.renderRow(element, index))
              :
              <EmptyRow>
                <Text variant='caption'>{localizationService.toLanguageString('cyclePlan.emptyList')}</Text>
              </EmptyRow>
            }
          </TableContainer>
        </TooltipContainer>
      </div>
    );
  }

  tooltipContentTemplate(props) {
    const intlService = provideIntlService(this);
    return (
      <span>
        {props.hoveredStudyEvent?.cycles?.map((element, index) => this.renderTooltipRow(element, index))}
        <span>{`${DateFormatter(new Date(props.hoveredStudyEvent.startDate), intlService)} - ${DateFormatter(new Date(props.hoveredStudyEvent.endDate), intlService)}`}</span>
      </span>
    );
  }

  renderTooltipRow(cycleData, index) {
    return (
      <TooltipItemContainer key={index} onClick={() => this.handleTooltipClick(cycleData)}>
        <LinkIcon className='k-icon k-i-redo'></LinkIcon>
        {cycleData.title}
        <br/>
        {cycleData.departmentName && (
          <>
            {cycleData.departmentName}
            <br/>
          </>
        )}
      </TooltipItemContainer>
    );
  }

  handleTooltipClick(cycleData) {
    const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId, studyPlanCycleDepartmentStudentId } = cycleData;
    this.props.navigate(`/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments/${studyPlanCycleDepartmentStudentId}`);
  }

  renderMonthHeader(month, year, index) {
    return (
      <HeaderDiv key={index} month={month} year={year}>
        <Text variant={'h6'}>{ROMAN_NUMERICALS[month]}</Text>
      </HeaderDiv>
    );
  }

  renderRow(element, index) {
    const localizationService = provideLocalizationService(this);
    return (
      <RowContainer key={index}>
        <ResidentDiv
          valid={element.intersectingEvents?.length === 0}
        >
          <ResidentInfoContainer>
            <Text variant='subtitle1' overflowWrap={true}>
              {`${element.name} ${element.surname}`}
            </Text>
            <BoldGreyText variant='caption'>
              {`${element.studyProgram}`}
            </BoldGreyText>
            <GreyText variant='caption'>
              {`${localizationService.toLanguageString('cyclePlan.studyYear')}: ${element.studyYear} | ${localizationService.toLanguageString('cyclePlan.creditsTotal')}: ${EmptyFormatter(element.creditsTotal, 0)}`}
            </GreyText>
          </ResidentInfoContainer>
        </ResidentDiv>
        {
          MONTHS.map((month, index) => this.renderMonth(month, index))
        }
        {
          element.events?.map((element, index) => this.renderEvent(element, index))
        }
        {
          element.intersectingEvents?.map((element, index) => this.renderEvent(element, index, true))
        }
      </RowContainer>
    );
  }

  renderEvent(element, index, isIntersecting) {
    const durationInDays = (element.transformedEndDate.getTime() - element.transformedStartDate.getTime()) / (1000 * 3600 * 24);
    const baseOffset = RESDIENT_DIV_WIDTH + RESDIENT_DIV_PADDING + 1;
    const eventOffsetInDays = (element.transformedStartDate.getTime() - new Date(this.props.year, MONTHS[0], 1).getTime()) / (1000 * 3600 * 24);
    const bordersWidthInEvent = element.transformedEndDate.getMonth() - element.transformedStartDate.getMonth() + 12 * (element.transformedEndDate.getFullYear() - element.transformedStartDate.getFullYear()) + 2;
    const borderWidthOnEventStart = element.transformedStartDate.getMonth() >= MONTHS[0] ? element.transformedStartDate.getMonth() - MONTHS[0] : element.transformedStartDate.getMonth() + (12 - MONTHS[0]);
    const randomizedColor = element.id && randomColor({ luminosity: 'light', seed: element.id * 1000, format: 'rgb' });
    return (
      <EventContainer key={index}>
        {isIntersecting &&
          <Event
            duration={bordersWidthInEvent + durationInDays * DAY_WIDTH - EVENT_START_BORDER_WIDTH}
            offset={borderWidthOnEventStart + baseOffset + eventOffsetInDays * DAY_WIDTH}
            position={'absolute'}
          >
            <IntersectingIcon className='k-icon k-i-backward-element'/>
          </Event>
        }
        <Event
          duration={bordersWidthInEvent + durationInDays * DAY_WIDTH - EVENT_START_BORDER_WIDTH}
          offset={borderWidthOnEventStart + baseOffset + eventOffsetInDays * DAY_WIDTH}
          intersecting={isIntersecting}
          position={'absolute'}
          title={' '}
          color={this.lightenColor(randomizedColor)}
          borderColor={this.darkenColor(randomizedColor)}
          onClick={event => this.handleEventClicked(event, element)}
          onMouseEnter={() => this.setHoveredStudyEvent(element)}
        >
          <EventTitle variant='caption' title={' '} onMouseEnter={() => this.setHoveredStudyEvent(element)}>
            {
              isIntersecting
                ? ''
                : element.abbreviation === '-'
                  ? element.title
                  : element.abbreviation
            }
          </EventTitle>
        </Event>
      </EventContainer>
    );
  }

  setHoveredStudyEvent(studyEvent) {
    this.setState({ hoveredStudyEvent: studyEvent });
  }

  renderMonth(month, index) {
    const { year } = this.props;
    return (
      <MonthDiv key={index} month={month} year={year}/>
    );
  }

  handleEventClicked(event, element) {
    if (element?.cycles?.length === 1) {
      const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId, studyPlanCycleDepartmentStudentId } = element.cycles[0];
      if (studyPlanId && studyPlanCycleId && studyPlanCycleStudentId && studyPlanCycleDepartmentStudentId) {
        this.props.navigate(`/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments/${studyPlanCycleDepartmentStudentId}`);
      }
    }
  }

  darkenColor(color) {
    if (color) {
      const colorValues = this.rgbColorToValueArray(color);
      const adjustmentValue = 25;
      const minValue = 0;
      const adjustedColorValues = colorValues.map(element => {
        return ~~element - adjustmentValue > minValue ? ~~element - adjustmentValue : minValue;
      });
      return `rgb(${adjustedColorValues[0]}, ${adjustedColorValues[1]}, ${adjustedColorValues[2]})`;
    }
  }

  lightenColor(color) {
    if (color) {
      const colorValues = this.rgbColorToValueArray(color);
      const adjustmentValue = 35;
      const maxValue = 255;
      const adjustedColorValues = colorValues.map(element => {
        return ~~element + adjustmentValue < maxValue ? ~~element + adjustmentValue : maxValue;
      });
      return `rgb(${adjustedColorValues[0]}, ${adjustedColorValues[1]}, ${adjustedColorValues[2]})`;
    }
  }

  rgbColorToValueArray(color) {
    return color.substring(4, color.length - 1).split(', ');
  }
}

const mapStateToProps = state => ({
  cyclePlans: state.cyclePlanListPage.cyclePlans,
  year: state.cyclePlanListPage.year
});

const mapDispatchToProps = dispatch => ({
  loadCyclePlanListPage: (payload) => dispatch(loadCyclePlanListPage(payload))
});

registerForIntl(ManagedStudentList);
registerForLocalization(ManagedStudentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManagedStudentList));