import * as React from 'react';
import styled, { css } from 'styled-components';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const MultiSelect = (props) => {
  const { valid, validationMessage, label, ...rest } = props;
  const hasEditorValue = props.value?.length > 0;
  const kendoMultiSelect = (
    <KendoMultiSelect
      {...rest}
      valid={valid}
      validationMessage={validationMessage}
    />
  );

  return (
    <Container $width={props.width} className={'k-form-fieldset'}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={hasEditorValue}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {kendoMultiSelect}
        </FloatingLabel>
        : kendoMultiSelect
      }
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default MultiSelect;