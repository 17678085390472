import * as React from 'react';
import styled, { css } from 'styled-components';
import { ComboBox as KendoComboBox } from '@progress/kendo-react-dropdowns';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';
import { GridLayout } from '../layouts';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
    margin: ${props.margin} !important;
  `}
`;

const ValueContainer = styled(GridLayout).attrs(props => ({
  columns: 'auto 24px',
  columnGap: '4px'
}))`
  align-items: center;
`;

const PlusButton = styled.span`
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0 0;
  padding: 8px 4px;
  border-width: 0 0 0 0px;
  box-sizing: border-box;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

export default class ComboBox extends React.Component {

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  render() {
    const {
      valid,
      validationMessage,
      total,
      skip,
      virtual,
      data,
      value,
      onAddClick,
      margin,
      label,
      ...rest
    } = this.props;

    const kendoComboBox = (
      <KendoComboBox
        {...rest}
        filterable={true}
        value={value}
        data={data ? data : []}
        virtual={virtual ? {
          total: total,
          pageSize: 50,
          skip: skip
        } : undefined }
        onPageChange={this.handlePageChange}
        onFilterChange={this.handleFilterChange}
        valid={valid}
        valueRender={(element) => {
          if (!onAddClick || value?.id > 0) {
            return element;
          }
          return (
            <ValueContainer>
              {element}
              <PlusButton key={2} className='k-icon k-i-plus' onClick={onAddClick}></PlusButton>
            </ValueContainer>
          );
        }}
      />
    );

    return (
      <Container $width={this.props.width} className={'k-form-fieldset'} margin={margin}>
        {label
          ?
          <FloatingLabel
            label={label}
            labelClassName='k-label'
            editorId={this.props.id}
            editorValue={this.props.value != null}
            editorValid={valid}
            editorDisabled={this.props.disabled}
            editorPlaceholder={this.props.placeholder}
          >
            {kendoComboBox}
          </FloatingLabel>
          : kendoComboBox
        }
        {!valid && <Error>{validationMessage}</Error>}
      </Container>
    );
  }

  async handlePageChange(e) {
    const { skip } = e.page;
    if (this.props.onFilter) {
      let params = {
        keyword: this.props.keyword,
        skip
      };
      if (this.props.additionalFilter) {
        params = { ...this.props.additionalFilter, ...params };
      }
      this.props.onFilter(params);
    }
  }

  async handleFilterChange(e) {
    const filter = e.filter;
    if (this.props.onFilter) {
      let params = {
        keyword: filter?.value,
        skip: this.props.skip
      };
      if (this.props.additionalFilter) {
        params = { ...this.props.additionalFilter, ...params };
      }
      this.props.onFilter(params);
    }
  }

}