import * as React from 'react';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';
import styled, { css } from 'styled-components';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const DatePicker = (props) => {
  const { value, valid, validationMessage, label, children, ...rest } = props;
  let normalizedValue = value;
  if (value === '' || value === undefined) {
    normalizedValue = null;
  }
  const kendoDatePicker = (
    <KendoDatePicker
      {...rest}
      formatPlaceholder={'short'}
      value={normalizedValue}
      valid={valid}
    />
  );

  return (
    <Container $width={props.width} className={'k-form-fieldset'}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={true}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {kendoDatePicker}
        </FloatingLabel>
        : kendoDatePicker
      }
      {!valid && <Error>{validationMessage}</Error>}
      {children}
    </Container>
  );
};

export default DatePicker;