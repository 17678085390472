import * as React from 'react';
import styled, { css } from 'styled-components';
import { TextArea } from '@progress/kendo-react-inputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const Textarea = (props) => {
  const { valid, validationMessage, width, label, ...rest } = props;
  const textarea = (
    <TextArea
      {...rest}
      valid={valid}
      validationMessage={validationMessage}
    />
  );

  return (
    <Container $width={width} className={'k-form-fieldset'}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={props.value}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {textarea}
        </FloatingLabel>
        : textarea
      }
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default Textarea;