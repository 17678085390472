import * as React from 'react';
import { TimePicker as KendoTimePicker } from '@progress/kendo-react-dateinputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

const TimePicker = (props) => {
  const { value, valid, validationMessage, label, ...rest } = props;
  let normalizedValue = value;
  if (value === '' || value === undefined) {
    normalizedValue = null;
  }

  const kendoTimePicker = (
    <KendoTimePicker
      {...rest}
      formatPlaceholder={'short'}
      value={normalizedValue}
      valid={valid}
    />
  );
  return (
    <fieldset className={'k-form-fieldset'}>
      {label
        ?
        <FloatingLabel
          label={label}
          labelClassName='k-label'
          editorId={props.id}
          editorValue={true}
          editorValid={valid}
          editorDisabled={props.disabled}
          editorPlaceholder={props.placeholder}
        >
          {kendoTimePicker}
        </FloatingLabel>
        : kendoTimePicker}
      {!valid && <Error>{validationMessage}</Error>}
    </fieldset>
  );
};

export default TimePicker;